/*--------------------------------------------------------------
## GENERAL
--------------------------------------------------------------*/
// .row-matrix{
//     margin-left: 0px;
//     margin-right: 0px;
// }
//
// .col-matrix{
//     padding-left: 0px;
//     padding-right: 0px;
//     @extend .col-12;
//
//     .news-detail-template &{
//         @extend .col-lg-10;
//     }
// }
/*--------------------------------------------------------------
## GENERAL
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## _swiperFullScrenn.twig
--------------------------------------------------------------*/
.img-wrapper-fullscreen {
    position: relative;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 350px;
    overflow: hidden;
    padding: 0px;

    @include media-breakpoint-up(sm) {
        height: 450px;
    }
    
    @include media-breakpoint-up(sm) {
        height: 650px;
    }
    
    @include media-breakpoint-up(lg) {
        height: calc(100vh - (#{$promobar__height} + #{$navbar__height}));
    }
    
    .content {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 2;

        .slide-content-position {
            width: 500px;
        }
    }
    
    .img-wrapper {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;

        img,
        source {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}

/*--------------------------------------------------------------
## _imgTextBtn.twig
--------------------------------------------------------------*/
$padding-overlay: 60px;

.container-img-text {
    @include media-breakpoint-down(lg) {
        padding-left: 0px;
        padding-right: 0px;

        .row {
            margin-left: 0px;
            margin-right: 0px;
        }
    }
}

.image-text-background {
    &.dark {
        background-color: $color__black;
    }
    
    .col-content {
        display: flex;
        justify-content: center;

        @include media-breakpoint-up(lg) {
            justify-content: flex-end;
            align-items: center;
        }
        
        .img-wrapper {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 400px;

            @include media-breakpoint-up(lg) {
                top: 0px;
                left: 12px;
                width: 58%;
                height: 100%;
            }
            
            @include media-breakpoint-up(xl) {
                width: 60%;
            }
            
            .img-section {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
        
        .text-wrapper {
            width: 90%;
            padding: 250px 0px 40px 0px;
            z-index: 2;

            .surtitle {
                display: none;
                color: $color__white;
                padding-left: $padding-overlay;
            }
            
            .text-wrapper-content {
                padding: 60px 20px;
                background-color: $color__white;

                .text-editor {

                    h1,
                    h2,
                    h3,
                    h4,
                    h5,
                    h6,
                    p,
                    ul,
                    ol,
                    li {
                        line-height: 1.75em;
                    }
                }
                
                .btn-lach {
                    @extend .black-stroke-arrow-right;
                }
            }
            
            @include media-breakpoint-up(sm) {
                .text-wrapper-content {
                    padding: $padding-overlay;
                }
            }
            
            @include media-breakpoint-up(md) {
                width: 90%;
            }
            
            @include media-breakpoint-up(lg) {
                width: 48%;
                padding: 75px 0px;

                .surtitle {
                    display: block;
                }
            }
            
            @include media-breakpoint-up(xl) {
                width: 42%;
            }
        }
    }
    
    &.light {
        .col-content {
            .text-wrapper {
                .surtitle {
                    color: $color__black;
                }
                
                .text-wrapper-content {
                    background-color: $color__black;

                    .surtitle-in,
                    .title-in {
                        color: $color__white;
                    }
                    
                    .text-editor {

                        p,
                        h1,
                        h2,
                        h3,
                        h4,
                        h5,
                        h6 {
                            color: $color__white;
                        }
                    }
                    
                    .btn-lach {
                        @extend .inverse;
                    }
                }
            }
        }
    }
}

/*--------------------------------------------------------------
## _imgTrioFullWidth.twig
--------------------------------------------------------------*/
.image-trio {
    .container-image-trio {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    
    .cta-content-wrapper {
        position: relative;
        height: 100%;

        .cta-text-hiding {
            position: relative;
            background-color: $color__white;
            width: 100%;
            padding: 60px 40px 60px 40px;
            transition: $transition__opacity;
            z-index: 2;

            @include media-breakpoint-up(lg) {
                opacity: 0;
                height: 100%;
            }
        }
        
        .title-cta {
            position: absolute;
            top: 40px;
            left: 40px;
        }
        
        .img-wrapper {
            top: 0px;
            left: 0px;
            height: 350px;
            width: 100%;

            img {
                height: 100%;
                max-height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: 50% 50%;
            }
            
            @include media-breakpoint-up(md) {
                height: 550px;
            }
            
            @include media-breakpoint-up(lg) {
                position: absolute;
                height: 100%;

                img {
                    height: 100%;
                }
            }
        }
        
        .cta-trigger {
            position: absolute;
            display: none;
            align-items: center;
            justify-content: center;
            height: 60px;
            width: 60px;
            background-color: $color__white;
            bottom: 0px;
            top: unset;
            left: 0px;

            .icon {
                font-size: 1.5em;
                transition: $transition__transform;
            }
            
            @include media-breakpoint-up(lg) {
                display: flex;
            }
        }
        
        @include media-breakpoint-up(lg) {
            &:hover {
                .cta-text-hiding {
                    opacity: 1;
                }
            }
        }
    }
}

/*--------------------------------------------------------------
## _textFramedDark.twig
--------------------------------------------------------------*/
.text-framed {
    &.dark {
        background-color: $color__black;
        color: $color__white;

        .frame {
            border: 2px solid $color__white;
            padding: 25px 20px 25px 20px;

            @include media-breakpoint-up(lg) {
                padding: 40px;
            }
            
            .text {
                color: $color__white;
            }
        }
    }
}

/*--------------------------------------------------------------
## _swiper-matrix-text-cta.twig
## _textImgOneThird.twig
--------------------------------------------------------------*/
.swiper-matrix-text-cta,
.img-text-one-third-wrapper {
    .text-wrapper {
        padding: 40px 20px;

        // height: calc(100% - 40px);
        @include media-breakpoint-up(md) {
            padding: 40px 30px;
        }
        
        &.img-right {
            @include media-breakpoint-up(xl) {
                padding: 65px 40px 65px 0px;
                height: calc(100% - 65px);
            }
        }
        
        &.img-left {
            @include media-breakpoint-up(xl) {
                padding: 65px 10px 65px 65px;
            }
        }
    }
}

/*--------------------------------------------------------------
## _comparativeTable.twig
--------------------------------------------------------------*/
.logo-partner {
    max-width: 70px;
}

.comparative-table {
    background-color: $color__white;

    .row-table-wrapper {

        .row-table,
        .row-table-head {

            .table-head,
            .table-cel {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                padding: 12px 10px;

                p,
                h4 {
                    margin-right: auto;
                }
                
                p {
                    margin-top: auto;
                    margin-bottom: auto;
                    line-height: 1.25em;
                }
            }
            
            .cel-inverse {
                background-color: $color__black;

                p {
                    color: $color__white;
                }
            }
            
            .table-head-border {
                border-top: 1px solid $color__black;
                border-left: 1px solid $color__black;
                border-bottom: 1px solid $color__black;
            }
        }
        
        .row-table-head {
            &:last-of-type {
                border-bottom: 1px solid $color__black;
            }
        }
        
        .row-table {
            border-left: 1px solid $color__black;

            .table-cel {
                border-right: 1px solid $color__black;
            }
            
            p {
                font-size: $font__size-14;
            }
            
            &:last-of-type {
                border-bottom: 1px solid $color__black;
            }
            
            &.back-grey {
                background-color: $color__grey-table;

                p {
                    font-weight: $font__weight-medium;
                }
            }
        }
    }
}

/*--------------------------------------------------------------
## _sectionRealisations.twig
--------------------------------------------------------------*/
.section-realisations {
    .img-wrapper-fit {
        position: relative;
    }
}
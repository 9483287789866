 /*--------------------------------------------------------------
# IMPORTANT
Il des style rewrite de text-editor dans _text-editor.scss
# IMPORTANT
--------------------------------------------------------------*/
 html {
     font-size: $font__size-body;
     font-style: normal;
 }
 
 body {
     overflow-x: hidden;
 }
 
 body,
 button,
 input,
 select,
 textarea {
     color: $color__black;
     font-family: $font__main;
     font-weight: $font__weight-regular;
     font-size: $font__size-body;
     line-height: $font__line-height-body;
 }
 
 /*--------------------------------------------------------------
 ## Font vertical align correction for Poppins
 --------------------------------------------------------------*/
 h1,
 h2,
 h3,
 h4,
 h5,
 h6,
 p,
 span {
     position: relative;
     top: $font__top-position-correction;
 }
 
 span span,
 p span,
 h1 span,
 h2 span,
 h3 span,
 h4 span,
 h5 span,
 h6 span,
 a span {
     position: initial;
     top: inherit;
 }
 
 .adjust-poppins {
     position: relative;
     top: $font__top-position-correction;
 }
 
 /*--------------------------------------------------------------
 ## Heading
 --------------------------------------------------------------*/
 h1,
 h2,
 h3,
 h4,
 h5,
 h6 {
     font-family: $font__heading;
     color: $color__black;
     margin-bottom: 0.75rem;
     margin-top: 0px;
     clear: both;
 }
 
 h1 {
     font-size: 1.8571rem;
     font-weight: $font__weight-bold;
     line-height: 0.9454em;
     letter-spacing: 0.0192em;

     @include media-breakpoint-up(lg) {
         font-size: 3.25rem;
     }
 }
 
 h2 {
     font-size: 1.5rem;
     font-weight: $font__weight-regular;
     line-height: 1.1666em;

     @include media-breakpoint-up(lg) {
         font-size: 2.25rem;
     }
 }
 
 h3 {
     font-size: 1.25rem;
     font-weight: $font__weight-regular;
     line-height: 1.0769em;

     @include media-breakpoint-up(lg) {
         font-size: 1.625rem;
     }
 }
 
 h4 {
     font-size: 1.0714rem;
     font-weight: $font__weight-regular;
     line-height: 1.0769em;

     @include media-breakpoint-up(lg) {
         font-size: 1.3125rem;
     }
 }
 
 h5 {
     font-size: 1rem;
     font-weight: $font__weight-medium;
     line-height: 1.0769em;
 }
 
 h6 {
     font-size: $font__size-14;
     font-weight: $font__weight-medium;
     line-height: 1.0769em;
 }
 
 p {
     font-size: 1rem;
     font-weight: $font__weight-regular;
     line-height: 1.625em;
     margin-bottom: 0.75em;
     color: $color__black;

     &.sm {
         font-size: 0.875rem;
     }
     
     &.xs {
         font-size: 0.75rem;
     }
 }
 
 blockquote {}
 
 /*--------------------------------------------------------------
## General styles
--------------------------------------------------------------*/
 b,
 strong,
 .bold,
 .text-weight-bold {
     font-weight: $font__weight-bold;
 }
 
 .text-weight-regular {
     font-weight: $font__weight-regular;
 }
 
 .text-weight-medium {
     font-weight: $font__weight-medium;
 }
 
 .italic {
     font-style: italic;
 }
 
 .font-ibm {
     font-family: $font__main;
 }
 
 .font-size-h1 {
     @extend h1;
 }
 
 .font-size-h2 {
     @extend h2;
 }
 
 .font-size-h3 {
     @extend h3;
 }
 
 .font-size-h4 {
     @extend h4;
 }
 
 .font-size-h5 {
     @extend h5;
 }
 
 .font-size-h6 {
     @extend h6;
 }
 
 .h1-display {
     font-size: 6rem;
     font-weight: $font__weight-bold;
     line-height: 1.125em;
     letter-spacing: 0.0192em;

     @include media-breakpoint-up(md) {
         font-size: 10.75rem;
     }
     
     @include media-breakpoint-up(lg) {
         font-size: 14.75rem;
     }
     
     @include media-breakpoint-up(xl) {
         font-size: 17.75rem;
     }
 }
 
 .font-size-body {
     font-size: $font__size-body;
 }
 
 .font-size-21 {
     font-size: 1.3125rem;
 }
 
 .font-size-14 {
     font-size: $font__size-14;
 }
 
 .font-size-16 {
     font-size: $font__size-body;
 }
 
 .font-size-12 {
     font-size: $font__size-12;
 }
 
 .letter-spacing-md {
     letter-spacing: 0.25em;
 }
 
 small,
 .text-legend-regular {
     font-size: 0.8571rem;
     font-weight: $font__weight-regular;
     line-height: 1.1666em;
 }
 
 .line-height-extra {
     line-height: 1.3846em !important;
 }
 
 .line-height-regular {
     line-height: $font__line-height-reg !important;
 }
 
 .line-height-p {
     line-height: 1.625em;
 }
 
 quote {

     &:before,
     &:after {
         position: relative;
         top: -0.5em;
         display: inline;
         font-size: 0.6em;
         font-weight: 900;
         font-family: 'Font Awesome\ 6 Pro';
     }
     
     &:before {
         content: '\f10d';
         margin-right: 3px;
     }
     
     &:after {
         content: '\f10e';
         margin-left: 3px;
     }
 }
 
 /*--------------------------------------------------------------
## Helpers
--------------------------------------------------------------*/
 .align-center {
     text-align: center;
 }
 
 /*--------------------------------------------------------------
## Copy
--------------------------------------------------------------*/
 /*
dfn, cite, em, i {
    font-style: italic;
}

address {
    margin: 0 0 1.5em;
}

pre {
    background: $color__grey-light;
    font-family: $font__pre;
    font-size: 0.75em;
    line-height: 1.25em;
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    padding: 1.6em;
}

code, kbd, tt, var {
    font-family: $font__code;
    font-size: 0.9375em;
}

abbr, acronym {
    border-bottom: 1px dotted $color__black-soft;
    cursor: help;
}

mark, ins {
    background: $color__black-soft;
    text-decoration: none;
}

big {
    font-size: 125%;
}
*/
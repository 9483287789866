/*creating usefull color classes*/
/*******************************************************
    Text colors
*******************************************************/
.color-white {
    color: $color__white;
}

.color-white-50 {
    color: $color__white-50;
}

.color-black {
    color: $color__black;
}

.color-green-primary {
    color: $color__green-primary;
}

.color-green-secondary {
    color: $color__green-secondary;
}

.color-grey-medium {
    color: $color__grey-medium;
}

.color-red {
    color: $color__red-error;
}

/*******************************************************
    Background colors
*******************************************************/
.back-color-white {
    background-color: $color__white;
}

.back-color-black {
    background-color: $color__black;
}

.back-color-green-primary {
    background-color: $color__green-primary;
}

.back-color-green-secondary {
    background-color: $color__green-secondary;
}

.back-color-grey-light {
    background-color: $color__grey-light;
}

.back-color-grey-medium {
    background-color: $color__grey-medium;
}

.back-color-orange {
    background-color: $color__orange;
}

.back-color-yellow {
    background-color: $color__yellow;
}

.back-color-blue {
    background-color: $color__blue;
}

.back-color-red-pink {
    background-color: #e7252f;
}

.back-color-violet {
    background-color: #a662b3;
}

.back-color-aqua {
    background-color: #5dcbdb;
}




/*--------------------------------------------------------------
## Tabs
--------------------------------------------------------------*/

.nav-tabs{
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: none;

    @include media-breakpoint-up(md) {
        flex-direction: row;
    }

    .nav-link{
        @extend .btn-lach;
        border-top: none;
        border-right: 2px solid $color__black;
        border-bottom: 2px solid $color__black;
        border-left: 2px solid $color__black;

        &:first-of-type{
            border-top: 2px solid $color__black;
        }

        &:hover,
        &.active{
            border-top: 2px solid $color__black;
            border-right: 2px solid $color__black;
            border-bottom: 2px solid $color__black;
            border-left: 2px solid $color__black;
            background-color: $color__black;
            color: $color__white;
        }

        @include media-breakpoint-up(md) {
            border-top: 2px solid $color__black;
            border-right: 2px solid $color__black;
            border-bottom: 2px solid $color__black;
            border-left: none;

            &:first-of-type{
                border-left: 2px solid $color__black;
            }
        }


    }

    &.big{
        position: relative;
        transform: translateY(-50px);

        @include media-breakpoint-up(md) {
            flex-direction: column;
        }

        @include media-breakpoint-up(lg) {
            flex-direction: row;
        }

        .nav-link{
            width: 100%;
            margin-bottom: 20px;
            padding: 25px 30px 25px 30px;
            text-transform: none;
            background-color: $color__black;

            @include media-breakpoint-up(md) {
                padding: 25px 40px 25px 40px;
            }

            @include media-breakpoint-up(lg) {
                width: auto;
                margin: 0px 10px;
                padding: 30px 45px 30px 45px;
            }

            @include media-breakpoint-up(xl) {
                padding: 40px 60px 40px 60px;
            }

            .icon{
                width: 25px;
                path{
                    stroke: $color__white;
                }
                .stroke-building02{
                    fill: $color__white;
                }
            }

            .tab-title{
                margin-left: 15px;
                color: $color__white;
            }

            .arrow{
                color: $color__white;
                font-size: 1.25rem;
                margin-left: 20px;
                transition: transform 0.2s linear;

                @include media-breakpoint-up(lg) {
                    font-size: 1.75rem;
                }
            }

            &:hover,
            &.active{

                .tab-title{
                    color: $color__white;
                }
                .arrow{
                    color: $color__green-primary;
                    transform: rotate(90deg);
                }
            }

        }
    }

}



/*************************************************
    tabs services
**************************************************/

#tabs-services{
    padding: 40px 30px;

    @include media-breakpoint-up(md) {
        padding: 60px 80px;
    }

    @include media-breakpoint-up(lg) {
        padding: 70px 80px;
    }

    @include media-breakpoint-up(xl) {
        padding: 80px 100px;
    }

}

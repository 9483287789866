/***************************************************************
    Padding
***************************************************************/
.padding-container-xxs {
    padding-top: 20px;
    padding-bottom: 20px;

    @include media-breakpoint-up(md) {
        // padding-top: 30px;
        // padding-bottom: 30px;
    }
}

.padding-container-xs {
    padding-top: 20px;
    padding-bottom: 20px;

    @include media-breakpoint-up(md) {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

.padding-container-sm {
    padding-top: 40px;
    padding-bottom: 40px;

    @include media-breakpoint-up(md) {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .news-detail-template & {
        padding-top: 20px;
        padding-bottom: 20px;

        @include media-breakpoint-up(md) {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }
}

.padding-container {
    padding-top: 60px;
    padding-bottom: 60px;

    @include media-breakpoint-up(md) {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .news-detail-template & {
        padding-top: 30px;
        padding-bottom: 30px;

        @include media-breakpoint-up(md) {
            padding-top: 50px;
            padding-bottom: 50px;
        }
    }
}

.padding-container-lg {
    padding-top: 100px;
    padding-bottom: 100px;

    @include media-breakpoint-up(md) {
        padding-top: 150px;
        padding-bottom: 150px;
    }

    .news-detail-template & {
        padding-top: 50px;
        padding-bottom: 50px;

        @include media-breakpoint-up(md) {
            padding-top: 80px;
            padding-bottom: 80px;
        }
    }
}

.padding-container-xl {
    padding-top: 150px;
    padding-bottom: 150px;

    @include media-breakpoint-up(md) {
        padding-top: 250px;
        padding-bottom: 250px;
    }

    .news-detail-template & {
        padding-top: 80px;
        padding-bottom: 80px;

        @include media-breakpoint-up(md) {
            padding-top: 125px;
            padding-bottom: 125px;
        }
    }
}

.padding-container-top {
    padding-top: 60px;

    @include media-breakpoint-up(md) {
        padding-top: 100px;
    }

    .news-detail-template & {
        padding-top: 30px;

        @include media-breakpoint-up(md) {
            padding-top: 50px;
        }
    }
}

.padding-container-bottom {
    padding-bottom: 60px;

    @include media-breakpoint-up(md) {
        padding-bottom: 100px;
    }

    .news-detail-template & {
        padding-bottom: 30px;

        @include media-breakpoint-up(md) {
            padding-bottom: 50px;
        }
    }
}

.padding-container-bottom-sm {
    padding-bottom: 20px;

    @include media-breakpoint-up(md) {
        padding-bottom: 30px;
    }
}

.padding-container-top-sm {
    padding-top: 40px;

    @include media-breakpoint-up(md) {
        padding-top: 80px;
    }

    .news-detail-template & {
        padding-top: 20px;

        @include media-breakpoint-up(md) {
            padding-top: 30px;
        }
    }
}

.padding-container-top-index-search {
    padding-top: 40px;

    @include media-breakpoint-up(md) {
        padding-top: 70px;
    }
}

/***************************************************************
    Margin
***************************************************************/
.margin-container-sm {
    margin-top: 40px;
    margin-bottom: 40px;

    @include media-breakpoint-up(md) {
        margin-top: 80px;
        margin-bottom: 80px;
    }
}

.margin-container {
    margin-top: 60px;
    margin-bottom: 60px;

    @include media-breakpoint-up(md) {
        margin-top: 100px;
        margin-bottom: 100px;
    }
}

.margin-container-lg {
    margin-top: 100px;
    margin-bottom: 100px;

    @include media-breakpoint-up(md) {
        margin-top: 150px;
        margin-bottom: 150px;
    }
}

.margin-container-xl {
    margin-top: 150px;
    margin-bottom: 150px;

    @include media-breakpoint-up(md) {
        margin-top: 250px;
        margin-bottom: 250px;
    }
}

.margin-container-img {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.container-negative-margin-top {
    margin-top: -50px;
}

/*--------------------------------------------------------------
## Padding-left breakpoint
--------------------------------------------------------------*/
.padding-left-breakpoint-nav {
    @include media-breakpoint-up(sm) {
        padding-left: 20px;
    }

    @include media-breakpoint-up(md) {
        padding-left: 30px;
    }

    @include media-breakpoint-up(lg) {
        padding-left: calc((100vw - 944px) / 2);
    }

    @include media-breakpoint-up(xl) {
        padding-left: calc((100vw - (1144px - 48px)) / 2);
    }

    @include media-breakpoint-up(xxl) {
        padding-left: calc((100vw - (1340px - 48px)) / 2);
    }
}

.padding-left-breakpoint {
    @include media-breakpoint-up(sm) {
        padding-left: 20px;
    }

    @include media-breakpoint-up(md) {
        padding-left: 30px;
    }

    @include media-breakpoint-up(lg) {
        padding-left: calc((100vw - 944px) / 2);
    }

    @include media-breakpoint-up(xl) {
        padding-left: calc((100vw - (1144px - 30px)) / 2);
    }

    @include media-breakpoint-up(xxl) {
        padding-left: calc((100vw - (1340px - 30px)) / 2);
    }
}

.padding-left-xl-breakpoint {
    // @include media-breakpoint-up(lg) {
    //     padding-left: calc( (100vw - 944px) / 2 );
    // }
    @include media-breakpoint-up(xl) {
        padding-left: calc((100vw - (1144px - 30px)) / 2);
    }

    @include media-breakpoint-up(xxl) {
        padding-left: calc((100vw - (1340px - 30px)) / 2);
    }
}

.padding-right-breakpoint {
    @include media-breakpoint-up(sm) {
        padding-right: 20px;
    }

    @include media-breakpoint-up(md) {
        padding-right: 30px;
    }

    @include media-breakpoint-up(lg) {
        // padding-right: calc( (100vw - 944px) / 2 );
    }

    @include media-breakpoint-up(xl) {
        padding-right: calc((100vw - (1144px - 30px)) / 2);
    }

    @include media-breakpoint-up(xxl) {
        padding-right: calc((100vw - (1340px - 30px)) / 2);
    }
}

.padding-right-xl-breakpoint {
    // @include media-breakpoint-up(lg) {
    // padding-right: calc( (100vw - 944px) / 2 );
    // }
    @include media-breakpoint-up(xl) {
        padding-right: calc((100vw - (1144px - 30px)) / 2);
    }

    @include media-breakpoint-up(xxl) {
        padding-right: calc((100vw - (1340px - 30px)) / 2);
    }
}



/*--------------------------------------------------------------
## General styles
--------------------------------------------------------------*/

.text-editor{


    p{
        line-height: $font__line-height-reg;
    }

    // Font size
    &.font-size-h3 {
        p, h1, h2, h3, h4, h5, h6{
            @extend h3;
        }
    }

    &.font-size-14 {

        p, h1, h2, h3, h4, h5, h6{
            @extend .font-size-14;
        }
    }

    // Line height
    &.line-height-extra{
        p, h1, h2, h3, h4, h5, h6{
            line-height: 1.3846em !important;
        }

    }

    // Font color
    &.color-white{
        p, h1, h2, h3, h4, h5, h6, ul, ol, li{
            color: $color__white;
        }
    }


    ul,
    ol{
        list-style: disc;
        color: inherit;
        padding-left: 20px;

      li{
        padding-bottom: 8px;

        ul,
        ol{
            padding-top: 12px;
          }

      }

    }

    a{
        text-decoration: underline;
        color: $color__green-primary;
    }


}

/*--------------------------------------------------------------
## Accordions
--------------------------------------------------------------*/
.accordion {
    &.accordion-mobile-menu {
        .accordion-item {
            background-color: transparent;
            border-bottom: 2px solid $color__white;

            .accordion-header {
                border: none;

                .accordion-button {
                    position: relative;
                    justify-content: flex-start;
                    background-color: transparent;
                    min-width: unset;
                    padding: 18px 0px 16px 0px;
                    border-radius: 0px;
                    border: none;
                    box-shadow: none;

                    a {
                        font-size: 14px;
                        position: relative;
                        color: $color__black;
                    }
                    
                    &:before,
                    &:after {
                        position: absolute;
                        content: "";
                        display: block;
                        top: 28px;
                        width: 10px;
                        height: 2px;
                        background-color: $color__black;
                        transition: transform 0.2s linear;
                    }
                    
                    &:before {
                        right: 4px;
                        transform: rotate(45deg);
                    }
                    
                    &:after {
                        right: 10px;
                        transform: rotate(-45deg);
                    }
                    
                    &.collapsed {
                        &:before {
                            transform: rotate(-45deg);
                        }
                        
                        &:after {
                            transform: rotate(45deg);
                        }
                    }
                    
                    &:hover {
                        background-color: transparent;
                    }
                    
                    &:focus {
                        color: inherit;
                        border: none;
                        background-color: transparent;
                        box-shadow: none;
                    }
                }
            }
            
            .accordion-collapse {
                border: none;

                .accordion-body {
                    padding: 0px;
                }
            }
        }
    }
    
    &.standard {
        .accordion-item {
            background-color: transparent;
            border-top: 1px solid $color__black;
            border-right: 1px solid $color__black;
            border-left: 1px solid $color__black;

            &:last-of-type {
                border-bottom: 1px solid $color__black;
            }
            
            .accordion-header {
                border: none;

                .accordion-button {
                    position: relative;
                    font-family: $font__heading;
                    font-weight: 700;
                    text-transform: none;
                    color: $color__black;
                    font-size: inherit;
                    justify-content: flex-start;
                    background-color: transparent;
                    min-width: unset;
                    padding: 28px 50px 20px 20px;
                    border-radius: 0px;
                    border: none;
                    box-shadow: none;

                    a {
                        font-size: 14px;
                        position: relative;
                        color: $color__black;
                    }
                    
                    &:before,
                    &:after {
                        position: absolute;
                        content: "";
                        display: block;
                        top: 50%;
                        width: 14px;
                        height: 2px;
                        background-color: $color__black;
                        transition: transform 0.2s linear;

                        @include media-breakpoint-up(md) {
                            width: 20px;
                            height: 3px;
                        }
                    }
                    
                    &:before {
                        right: 15px;
                        transform: rotate(-45deg);
                    }
                    
                    &:after {
                        right: 15px;
                        transform: rotate(45deg);
                    }
                    
                    &.collapsed {
                        &:before {
                            transform: rotate(0deg);
                        }
                        
                        &:after {
                            transform: rotate(-90deg);
                        }
                    }
                    
                    &:hover {
                        background-color: transparent;
                    }
                    
                    &:focus {
                        color: inherit;
                        border: none;
                        background-color: transparent;
                        box-shadow: none;
                    }
                }
            }
            
            .accordion-collapse {
                border: none;

                .accordion-body {
                    padding: 20px 40px 20px 20px;
                }
            }
        }
        
        &.black {
            .accordion-item {
                background-color: transparent;
                border-top: 1px solid $color__white;
                border-right: 1px solid $color__white;
                border-left: 1px solid $color__white;

                &:last-of-type {
                    border-bottom: 1px solid $color__white;
                }
                
                .accordion-header {
                    .accordion-button {
                        color: $color__white;

                        &:focus {
                            color: $color__white !important;
                        }
                        
                        a {
                            color: $color__white !important;
                        }
                        
                        &:before,
                        &:after {
                            background-color: $color__white;
                        }
                        
                        &:hover {
                            background-color: transparent;
                        }
                        
                        &:focus {
                            color: inherit;
                            border: none;
                            background-color: transparent;
                            box-shadow: none;
                        }
                    }
                }
                
                .accordion-collapse {
                    .accordion-body {
                        .text-editor {

                            h1,
                            h2,
                            h3,
                            h4,
                            h5,
                            h6,
                            p,
                            ul li ol {
                                color: $color__white !important;
                            }
                        }
                    }
                }
            }
        }
    }
    
    &.standard-line {
        .accordion-item {
            background-color: transparent;
            border-bottom: 1px solid $color__black;

            .accordion-header {
                border: none;

                .accordion-button {
                    position: relative;
                    font-family: $font__heading;
                    font-weight: $font__weight-medium;
                    text-transform: none;
                    color: $color__black;
                    font-size: inherit;
                    justify-content: flex-start;
                    background-color: transparent;
                    min-width: unset;
                    padding: 20px 50px 20px 0px;
                    border-radius: 0px;
                    border: none;
                    box-shadow: none;

                    a {
                        font-size: 14px;
                        color: $color__black;
                    }
                    
                    &:before,
                    &:after {
                        position: absolute;
                        content: "";
                        display: block;
                        top: 50%;
                        width: 14px;
                        height: 2px;
                        background-color: $color__black;
                        transition: transform 0.2s linear;

                        @include media-breakpoint-up(md) {
                            width: 20px;
                            height: 3px;
                        }
                    }
                    
                    &:before {
                        right: 15px;
                        transform: rotate(-45deg);
                    }
                    
                    &:after {
                        right: 15px;
                        transform: rotate(45deg);
                    }
                    
                    &.collapsed {
                        &:before {
                            transform: rotate(0deg);
                        }
                        
                        &:after {
                            transform: rotate(-90deg);
                        }
                    }
                    
                    &:hover {
                        background-color: transparent;
                    }
                    
                    &:focus {
                        color: inherit;
                        border: none;
                        background-color: transparent;
                        box-shadow: none;
                    }
                }
            }
            
            .accordion-collapse {
                border: none;

                .accordion-body {
                    padding: 0px 20px 20px 20px;
                }
            }
        }
    }
    
    &.on-dark {
        .accordion-item {
            background-color: transparent;
            border: none;

            .accordion-header {
                position: relative;
                border: none;

                .accordion-button {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    font-family: $font__heading;
                    font-weight: $font__weight-medium;
                    text-transform: none;
                    color: $color__white;
                    font-size: $font__size-body;
                    justify-content: flex-start;
                    background-color: transparent !important;
                    min-width: unset;
                    min-height: 42px;
                    padding: 8px 30px 8px 0px;
                    border-radius: 0px;
                    border: none;
                    box-shadow: none;

                    .icon-wrapper {
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        height: 42px;
                        width: 45px;
                        content: "";
                        display: block;
                        transform: rotate(90deg);
                        transition: transform 0.2s linear;

                        &:before,
                        &:after {
                            position: absolute;
                            content: "";
                            display: block;
                            transition: transform 0.2s linear, background-color 0.2s linear, border-color 0.2s linear;

                            @include media-breakpoint-up(md) {
                                width: 20px;
                                height: 3px;
                            }
                        }
                        
                        &:before {
                            top: calc(50% + 1px);
                            right: 0px;
                            width: 22px;
                            height: 2px;
                            background-color: $color__green-primary;
                        }
                        
                        &:after {
                            top: 50%;
                            right: 5px;
                            width: 12px;
                            height: 12px;
                            border-right: 2px solid $color__green-primary;
                            border-top: 2px solid $color__green-primary;
                            transform: rotate(45deg) translateY(-50%);
                        }
                    }
                    
                    &:after {
                        display: none;
                    }
                    
                    a {
                        font-size: 14px;
                        position: relative;
                        color: $color__black;
                    }
                    
                    &.collapsed {
                        .icon-wrapper {
                            transform: rotate(0deg);

                            &:before {
                                background-color: $color__white;
                            }
                            
                            &:after {
                                border-color: $color__white;
                                transform: rotate(45deg) translateY(-50%);
                            }
                        }
                    }
                    
                    &:hover {
                        color: $color__green-primary;
                        background-color: transparent;

                        &+.icon-wrapper {
                            &:before {
                                background-color: $color__green-primary;
                            }
                            
                            &:after {
                                border-color: $color__green-primary;
                            }
                        }
                    }
                    
                    &:focus {
                        color: $color__white;
                        border: none;
                        background-color: transparent;
                        box-shadow: none;
                    }
                }
            }
            
            .accordion-collapse {
                border: none;

                .accordion-body {
                    padding: 0px 30px 10px 30px;

                    .project-link {
                        color: $color__white;
                        transition: $transition__color-link;

                        &:hover {
                            color: $color__green-primary;
                        }
                    }
                }
            }
        }
    }
    
    &.filter {
        .accordion-item {
            position: relative;
            background-color: transparent;
            border: none;

            .accordion-header {
                position: relative;
                border: none;

                .accordion-button {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    color: $color__black;
                    font-weight: $font__weight-regular;
                    text-transform: none;
                    color: $color__black;
                    font-size: $font__size-14;
                    justify-content: flex-start;
                    background-color: $color__white !important;
                    min-width: unset;
                    min-height: 55px;
                    padding: 20px 30px 16px 16px;
                    border-radius: 0px;
                    border: none;
                    box-shadow: none;

                    &:before,
                    &:after {
                        position: absolute;
                        display: block;
                        content: "";
                        top: 50%;
                        width: 8px;
                        height: 2px;
                        background-color: $color__green-primary;
                        pointer-events: none;
                        transition: transform 0.2s linear;
                    }
                    
                    &:before {
                        right: 13px;
                        transform: rotate(45deg);
                    }
                    
                    &:after {
                        right: 18px;
                        transform: rotate(-45deg);
                    }
                    
                    &:after {
                        // display: none;
                    }
                    
                    a {
                        font-size: 14px;
                        position: relative;
                        color: $color__black;
                    }
                    
                    &.collapsed {
                        &:before {
                            transform: rotate(-45deg);
                        }
                        
                        &:after {
                            transform: rotate(45deg);
                        }
                    }
                }
            }
            
            .accordion-collapse {
                border: none;
                z-index: 1000;
                padding-top: 25px;

                @include media-breakpoint-up(lg) {
                    position: absolute;
                    left: 0px;
                    top: 55px;
                    width: 100%;
                }
                
                .accordion-body {
                    padding: 0px 30px 10px 30px;

                    .project-link {
                        color: $color__white;
                        transition: $transition__color-link;

                        &:hover {
                            color: $color__green-primary;
                        }
                    }
                }
            }
        }
    }
}
/***************************************************************
    Residential Project index
***************************************************************/
.btn-residential-wrapper {
    position: fixed;
    top: calc(#{$navbar__height-mobile} + #{$promobar__height});
    right: 0px;
    transition: top 0.2s linear;

    .window-scroll & {
        top: calc($navbar__height-mobile - 2px);
    }
    
    @include media-breakpoint-up(lg) {
        top: calc(#{$navbar__height} + #{$promobar__height});

        .window-scroll & {
            top: calc($navbar__height-scroll - 1px);
        }
    }
}

.btn-legend-toggler {
    background-color: $color__black;
    padding: 15px 15px 11px 15px;
    transition: background-color 0.2s linear;
    height: 50px;

    .text {
        color: $color__white;
    }
    
    &:focus {
        background-color: $color__black;
    }
    
    &:hover {
        background-color: $color__black;

        .offcanvasPop {
            opacity: 1;
            transform: translate(0px, 100%);

            @include media-breakpoint-up(lg) {
                transform: translate(-93px, 100%);
            }
        }
    }
}

.legend-dot-offcanva {
    height: 16px;
    min-height: 16px;
    width: 16px;
    min-width: 16px;
    border-radius: 50%;
    margin-right: 4px;
}

.btn-residential-toggler {
    background-color: $color__black;
    width: 90px;
    padding: 15px 15px 11px 15px;
    transition: background-color 0.2s linear;
    height: 50px;

    .icon {
        width: 18px;

        .class-lot02 {
            stroke: $color__white;
        }
    }
    
    .lot-hamburger-wrapper {
        position: relative;
        margin-left: 10px;
        height: 20px;
        width: 25px;

        .line-01,
        .line-02,
        .line-03 {
            position: absolute;
            left: 0px;
            height: 2px;
            width: 25px;
            background-color: $color__white;
        }
        
        .line-01 {
            top: 0px;
        }
        
        .line-01 {
            top: calc(50% - 1px);
        }
        
        .line-03 {
            bottom: 0px;
        }
    }
}

/***************************************************************
    Residential Project entry
***************************************************************/
.dots-grid {
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
    margin-right: 10px;

    .dot-row {
        display: flex;
        flex-direction: row;

        .dot {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            border: 2px solid $color__black;
            margin: 1px;
        }
    }
}

.iframe-wrapper {
    width: 100%;
    height: 725px;
    background-color: $color__grey-medium-light;

    .residential-project-iframe {
        height: 725px;
        width: 100%;
    }
}

.project-legend-wrapper {
    .project-legend {
        display: flex;
        flex-direction: row;
        align-items: center;

        .legend-dot {
            height: 22px;
            min-height: 22px;
            width: 22px;
            min-width: 22px;
            border-radius: 50%;
            margin-right: 12px;
        }
        
        .legend-text {
            margin-bottom: 0px;
        }
    }
}

.project-icon-wrapper {
    .icon.lot {
        width: 20px;
    }
}
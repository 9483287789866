

google-map{
    height: 600px;

    google-map-marker {
        span{
            background-color: $color__white;
            display: block;
            position: relative;
            z-index: 2000;
        }
    }

}


.search-map {
    width: 100%;
    height: 600px;
    border: #ccc solid 1px;


    .map-marker{
        height: 25px;
        width: 25px;
        background-color: $color__black;
    }

}

// Fixes potential theme css conflict.
.search-map img {
   // max-width: inherit !important;
}



/***************************************************************
    Info Window
***************************************************************/


/*little part under window*/
.gm-style-iw-tc{
    &:after{
        background-color: $color__black !important;
    }
}

.gm-style .gm-style-iw-c{
    border-radius: 0px;
    padding: 0px !important;
}


button.gm-ui-hover-effect{
    top: 0px !important;
    min-width: unset;
    background-color: $color__black !important;
    opacity: 1 !important;


    span{
        background-color: #ffffff;
    }
}


.gm-style-iw.gm-style-iw-c{
    max-width: 750px !important;
    overflow-y: auto;

    .gm-style-iw-d{
        overflow: auto !important;
        background-color: $color__black;
    }
}

.info-window-wrapper{
    max-width: 300px;
    padding: 6px;
    overflow-y:  scroll;

    &.large{
        max-width: 425px;
    }

    &::-webkit-scrollbar {
      width: 1em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }


    .info-header{
        display: flex;
        flex-direction: row;
        padding: 8px 16px 8px 8px;
        color: $color__white;
        background-color: $color__black;

        .icon{
            width: 18px;
            margin-right: 6px;
        }

        p{
            color: $color__white;
        }
    }

    .marker-img{
        width: 100%;
        max-width: 200px;
    }

    p{
       line-height: 18px !important;
    }

    .info-window-link{
        text-decoration: underline;
        font-weight: $font__weight-bold;
    }

}




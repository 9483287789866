/*------------------------------------------------------------------------------
# Videos
------------------------------------------------------------------------------*/
.video-radius {
    border-radius: 20px;
}

picture {
    height: auto;
    max-width: 100%;

    img,
    source {
        height: auto;
        max-width: 100%;
    }
}

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    max-width: 100%;
    height: auto;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/*------------------------------------------------------------------------------
# Images ratios
------------------------------------------------------------------------------*/
.img-wrapper-fit {
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
    
    &.ratio-hor-16-9 {
        aspect-ratio: 16/9;
    }
    
    &.ratio-ver-16-9 {
        aspect-ratio: 9/16;
    }
    
    &.ratio-square {
        aspect-ratio: 1/1;
    }
    
    //for 3 img 2 columns
    &.ratio-solo {
        @include media-breakpoint-up(lg) {
            aspect-ratio: 0.852;
        }
        
        @include media-breakpoint-up(xl) {
            aspect-ratio: 0.855;
        }
        
        @include media-breakpoint-up(xxl) {
            aspect-ratio: 0.859;
        }
    }
}
/*--------------------------------------------------------------
## Global elements
--------------------------------------------------------------*/
.img-notice {
    position: absolute;
    bottom: 0px;
    right: 0px;
    color: $color__white;
    padding: 10px 10px 6px 10px;
    background-color: $color__black;

    .icon {
        position: relative;
        top: -2px;
    }
    
    &.standard {
        position: static;
        bottom: auto;
        right: auto;
    }
    
    &.absolute {
        position: absolute;
        bottom: 0px;
        right: 12px;
        z-index: 10;

        .icon {
            top: 0px;
        }
    }
}

.min-height-price {
    min-height: 32px;
}

.available-notice {
    position: absolute;
    top: 0px;
    left: 0px;
    color: $color__white;
    font-weight: $font__weight-bold;
    padding: 8px 20px 8px 20px;
    background-color: $color__black;
    z-index: 2;

    &.right {
        top: 0px;
        left: unset;
        right: 12px;
    }
    
    &.red {
        background-color: $color__red-error;
    }
    
    &.green {
        background-color: $color__green-primary;
    }
}

.animal-notice {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;

    img {
        width: 35px;
    }
}

/*--------------------------------------------------------------
## CARD FOR RENT
--------------------------------------------------------------*/
$cardRentPadding: 20px;

.size-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 20px 12px 20px;
    height: 100%;
    color: $color__white;
    background-color: $color__black;

    .size-title {
        top: 0px;
    }
}

.rent-details {
    display: flex;
    align-items: center;
    margin-left: 6px;

    .rent-detail {
        display: flex;
        align-items: center;
        padding: 10px;

        .icon {
            width: 17px;

            &.lot {
                width: 13px;
            }
            
            &.bed {
                width: 19px;
            }
        }
        
        .detail {
            margin-top: 4px;
            margin-left: 4px;

            .cards & {
                font-size: $font__size-13;
            }
        }
    }
}

.card-for-rent {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: $color__white;
    transition: box-shadow 0.3s linear;

    .card-header {
        position: relative;
        border: 2px solid $color__black;

        .img-wrapper {
            width: 100%;
            aspect-ratio: 16 / 9;

            .img-card-for-rent {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }
    
    .card-body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-grow: 1;
        border-left: 2px solid $color__black;
        border-right: 2px solid $color__black;
        padding: $cardRentPadding;

        p,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            line-height: 1.25em;
        }
        
        &.with-bottom {
            border-bottom: 2px solid $color__black;
        }
    }
    
    .card-footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border: 2px solid $color__black;

        &.with-padding {
            padding: $cardRentPadding;
        }
    }
    
    &:hover {
        box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.2);
    }
}

/*--------------------------------------------------------------
## Offices cards contact page
--------------------------------------------------------------*/
.office-card {
    padding: 25px 0px 40px 0px;
    // border-top: 2px solid $color__black;
    transition: $transition__color-link;

    &:first-of-type {
        padding-top: 50px;
    }
    
    .link-map {
        &:hover {
            color: $color__green-primary;
        }
    }
}

/*--------------------------------------------------------------
## CARD Réalisations
--------------------------------------------------------------*/
.card-realisation {
    display: block;
}

/*--------------------------------------------------------------
## CARD Team
--------------------------------------------------------------*/
.team-card {
    // position: relative;
    //     .team-member-description {
    //         top: 0px;
    //         left: 0px;
    //         width: 100%;
    //         cursor: pointer;
    //         background-color: $color__white;
    //
    //         @include media-breakpoint-up(lg) {
    //             position: absolute;
    //             display: flex;
    //             align-items: center;
    //             opacity: 0;
    //             height: 100%;
    //             padding: 60px 40px 50px 40px;
    //             border: 1px solid $color__black;
    //             transition: $transition__opacity;
    //         }
    //     }
    //
    //     @include media-breakpoint-up(lg) {
    //         &:hover {
    //             .team-member-description {
    //                 opacity: 1;
    //             }
    //         }
    //     }
}
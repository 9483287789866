/*--------------------------------------------------------------
## Modals
--------------------------------------------------------------*/
.modal {
    &.modal-team {
        border-radius: 0px;
        z-index: 1000001;

        .modal-dialog {
            @include media-breakpoint-up(sm) {
                max-width: 600px;
            }
            
            .modal-content {
                background-color: $color__black;
                border-radius: 0px;

                .modal-header {
                    background-color: transparent;
                    border: none;
                    padding: 20px 20px 20px 20px;

                    @include media-breakpoint-up(sm) {
                        padding: 20px 20px 30px 40px;
                    }
                    
                    @include media-breakpoint-up(lg) {
                        padding: 20px 20px 30px 60px;
                    }
                    
                    .modal-close-wrapper {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        width: 100%;

                        .btn-close {
                            font-size: 1.5rem;
                            background-image: none;
                            background-color: transparent;
                            border: none !important;
                            opacity: 1;
                            transition: $transition__opacity;
                            color: $color__white;

                            &:hover,
                            &:focus {
                                opacity: 0.6;
                                background-color: transparent;
                                border: none;
                                box-shadow: none;
                            }
                        }
                    }
                }
                
                .modal-body {
                    padding: 0px 20px 20px 20px;

                    @include media-breakpoint-up(sm) {
                        padding: 0px 40px 30px 40px;
                    }
                    
                    @include media-breakpoint-up(lg) {
                        padding: 0px 60px 40px 60px;
                    }
                }
                
                .modal-footer {
                    border-top: none;
                }
            }
        }
    }
}
/*******************************************************
    Swipper general
*******************************************************/
/***************************
    Pagination dynamic
****************************/
.swiper-pagination-bullets-dynamic {
    .swiper-pagination-bullet {
        border-radius: 0px;
        height: 6px;
        width: 10px;
        background: $color__grey-medium;

        &.swiper-pagination-bullet-active-prev,
        &.swiper-pagination-bullet-active-next {
            opacity: 1;
        }
        
        &.swiper-pagination-bullet-active-main {
            background: $color__green-primary;
            width: 28px;
        }
        
        &.swiper-pagination-bullet-active-prev-prev,
        &.swiper-pagination-bullet-active-next-next {
            opacity: 1;
        }
    }
}

/***************************
    Pagination
****************************/
.swiper-pagination-bullets {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    bottom: 0px !important;

    @include media-breakpoint-up(xl) {
        .img-left & {
            justify-content: flex-start;
        }
    }
    
    .swiper-pagination-bullet {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        text-align: center;
        border-radius: 0px;
        margin-left: 0px !important;
        margin-right: 0px !important;
        line-height: 1.625em;
        font-size: $font__size-body;
        font-weight: $font__weight-regular;
        color: $color__white;
        opacity: 1;
        background: $color__black;
        transition: $transition__color-back;

        &:hover {
            color: $color__black;
            background-color: $color__green-primary;
        }
    }
    
    .swiper-pagination-bullet-active {
        color: $color__green-primary;
        text-decoration: underline;
    }
}

/***************************
    Previous / next button
****************************/
.swiper-button-prev,
.swiper-button-next {
    position: absolute;
    top: auto;
    bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: $btn-swiper-nav-width !important;
    height: $btn-swiper-nav-height !important;
    cursor: pointer;
    background-color: $color__black;
    z-index: 10;
    transition: $transition__color-back;

    &:after {
        font-family: 'Font Awesome\ 6 Pro';
        display: block;
        font-weight: 400;
        font-size: 16px !important;
        color: $color__white;
    }
    
    &:hover {
        background-color: $color__green-primary;

        &:after {
            color: $color__white;
        }
    }
    
    &.swiper-button-lock {
        display: none !important;
    }
}

.swiper-button-prev {
    left: auto;
    right: $btn-swiper-nav-width;

    &:after {
        content: "\f053" !important;
    }
}

.swiper-button-next {
    right: 0px;

    &:after {
        content: "\f054" !important;
    }
}

/****************************************************
    Previous / next button
    Middle allignement grey
*****************************************************/
.swiper-button-prev.middle,
.swiper-button-next.middle {
    top: 50%;
    bottom: auto;
    background-color: $color__grey-medium-light;
    width: 40px !important;
    height: 40px !important;

    &:after {
        font-size: 24px !important;
    }
    
    &:hover {
        background-color: $color__green-primary;
    }
}

.swiper-button-prev.middle {
    left: 0px;

    &:after {
        content: "\f177" !important;
    }
}

.swiper-button-next.middle {
    right: 0px;

    &:after {
        content: "\f178" !important;
    }
}

/*******************************************************
    Swipper full height
*******************************************************/
.swiper-fullheight {
    width: 100%;
    max-width: 100%;
    width: 100%;
    height: 350px;
    overflow: hidden;
    padding: 0px;

    @include media-breakpoint-up(sm) {
        height: 450px;
    }
    
    @include media-breakpoint-up(sm) {
        height: 650px;
    }
    
    @include media-breakpoint-up(lg) {
        height: calc(100vh - (#{$promobar__height} + #{$navbar__height}));
    }
    
    .swiper-wrapper {
        .swiper-slide {

            // position: relative;
            .slide-content {
                position: relative;
                width: 100%;
                height: 100%;
                z-index: 2;

                .content-max-width {
                    max-width: 500px;
                }
            }
            
            .img-wrapper {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;

                img,
                source {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
            
            img,
            source {
                object-fit: cover;
                object-position: center;
            }
            
            .overlay {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.3);
            }
        }
    }
}

/*******************************************************
    Swipper text CTA
*******************************************************/
.swiper-text-cta {
    position: relative;
    overflow: hidden;
    padding-bottom: 40px;

    @include media-breakpoint-up(sm) {
        // height: 450px;
    }
    
    @include media-breakpoint-up(md) {
        // height: 600px;
    }
    
    @include media-breakpoint-up(xl) {
        height: 100%;
    }
    
    .swiper-wrapper {
        .swiper-slide {
            position: relative;
            display: flex;
            align-items: center;

            .img-wrapper-cta {
                position: absolute;
                top: 0px;
                left: 0px;
            }
        }
    }
}

.img-wrapper-cta {
    width: 100%;
    height: 100%;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
}

/*******************************************************
    Swipper full width
*******************************************************/
.swiper-fullwidth {
    position: relative;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    max-height: 350px;
    height: auto;
    overflow: hidden;
    padding: 0px;

    &:hover {
        .btn-open-lightgallery {
            i {
                color: $color__green-primary;
            }
        }
    }
    
    @include media-breakpoint-up(sm) {
        max-height: 400px;
    }
    
    @include media-breakpoint-up(sm) {
        max-height: 600px;
    }
    
    @include media-breakpoint-up(lg) {
        max-height: 750px;
    }
    
    @include media-breakpoint-up(xl) {
        max-height: 875px;
    }
    
    .swiper-wrapper {
        .swiper-slide {
            display: flex;
            justify-content: center;

            // align-items: center;
            img {
                width: auto;
                max-height: 875px;
            }
        }
    }
}

.swiper-fullwidth-img-solo img {
    max-height: 350px;

    @include media-breakpoint-up(sm) {
        max-height: 400px;
    }
    
    @include media-breakpoint-up(sm) {
        max-height: 600px;
    }
    
    @include media-breakpoint-up(lg) {
        max-height: 700px;
    }
}

.swiper-nav-wrapper {
    position: relative;
    padding: 0px 100px;
    overflow: hidden;

    &:before,
    &:after {
        position: absolute;
        top: 0px;
        content: "";
        display: block;
        width: 100px;
        height: 140px;
        z-index: 2;
    }
    
    &:before {
        left: 0px;
    }
    
    &:after {
        right: 0px;
    }
    
    &.dark {
        padding: 20px 100px 0px 100px;

        &:before,
        &:after {
            background-color: $color__black;
        }
    }
    
    &.light {
        background-color: $color__white;

        .swiper-button-prev,
        .swiper-button-next {
            top: 37px;
            bottom: auto;
        }
        
        .swiper-button-prev {
            left: -100px;
            right: auto;
        }
        
        .swiper-button-next {
            right: -100px;
        }
        
        &:before,
        &:after {
            background-color: $color__white;
        }
    }
    
    .swiper-fullwidth-nav {
        position: relative;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        height: 140px;
        background-color: $color__white;
        padding: 20px 0px;

        .swiper-wrapper {
            .swiper-slide {
                filter: brightness(0.6);
                transition: filter 0.2s linear;

                &.swiper-slide-thumb-active {
                    filter: brightness(1);
                }
                
                .img-wrapper {
                    img {
                        width: 100%;
                        height: 100px;
                        object-fit: cover;
                        object-position: center;
                    }
                }
            }
        }
        
        .swiper-button-prev,
        .swiper-button-next {
            top: 57px;
            bottom: auto;

            &.swiper-button-lock {
                display: flex !important;
                opacity: 0.2;
            }
        }
        
        .swiper-button-prev {
            left: -100px;
            right: auto;
        }
        
        .swiper-button-next {
            right: -100px;
        }
    }
}

/*******************************************************
    Swipper promo bar
*******************************************************/
.swiper-container.swiper-promo-bar {

    // overflow-x: hidden;
    .swiper-wrapper {
        transition-timing-function: linear !important;

        .swiper-slide {
            width: auto;

            .promo-bar-text {
                padding-top: 7px;
                display: inline-block;
                width: auto;
            }
        }
    }
    
    @include media-breakpoint-up(md) {
        height: auto;
    }
}

/***************************
    LightGallery
****************************/
.btn-open-lightgallery {
    position: absolute;
    left: 20px;
    top: 10px;
    z-index: 20;

    i {
        color: $color__white;
        transition: $transition__color-link;
    }
    
    &:hover {
        i {
            color: $color__green-primary;
        }
    }
}

.lg-container {
    .lg-outer {
        z-index: 1000001;

        .lg-components {
            .lg-thumb-align-middle {
                .lg-thumb {
                    .lg-thumb-item {
                        border: 2px solid transparent;
                        border-radius: 0px;
                        opacity: 0.8;

                        &:hover,
                        &.active {
                            opacity: 1;
                            border-color: $color__green-primary;
                        }
                    }
                }
            }
        }
    }
}
/*--------------------------------------------------------------
## Buttons
--------------------------------------------------------------*/
$padding_arrow: 15px 25px 11px 30px;
$margin_arrow-after: 2px 0px 0px 16px;

button,
.btn-lach {
    text-decoration: none !important;
    -webkit-appearance: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $color__black;
    font-size: $font__size-14;
    font-weight: $font__weight-bold;
    text-align: center;
    padding: 15px 30px 11px 30px;
    min-width: 55px;
    width: auto;
    border-radius: 0px;
    background-color: $color__white;
    border: 1px solid $color__black;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: background-color 0.2s linear, border 0.2s linear;

    &:hover {
        color: $color__white;
        background-color: $color__green-primary;
        border-color: $color__green-primary;
        text-decoration: none;
    }
    
    &:focus {
        color: $color__white;
        background-color: $color__black;
        text-decoration: none;
    }
    
    &.transparent {
        background-color: transparent;
        border-color: $color__white;
        color: $color__white;

        &:hover {
            color: $color__black;
            background-color: $color__white;
            text-decoration: none;
        }
    }
    
    &.black-stroke-arrow-right {
        background-color: transparent;
        border-color: $color__black;
        color: $color__black;

        // padding: 15px 30px 12px 30px;
        &.inverse {
            border-color: $color__white;
            color: $color__white;
        }
        
        &:after {
            @extend %fa-icon;
            @extend .fa-light;
            @extend .fa-sharp;
            content: fa-content($fa-var-arrow-right-long);
            font-size: 1.5em;
            margin: $margin_arrow-after;
            transition: transform 0.2s linear;
        }
        
        &:hover {
            color: $color__white;
            background-color: $color__green-primary;
            border-color: $color__green-primary;
            text-decoration: none;

            &:after {
                transform: translateX(10px);
            }
        }
    }
    
    &.transparent-arrow-right {
        background-color: transparent;
        border-color: $color__white;
        color: $color__white;
        padding: $padding_arrow;

        &:after {
            @extend %fa-icon;
            @extend .fa-regular;
            @extend .fa-sharp;
            content: fa-content($fa-var-arrow-right-long);
            font-size: 1.5em;
            margin: $margin_arrow-after;
            transition: transform 0.2s linear;
        }
        
        &:hover {
            color: $color__white;
            background-color: $color__green-primary;
            border-color: $color__green-primary;
            text-decoration: none;

            &:after {
                transform: translateX(10px);
            }
        }
    }
    
    &.white-arrow-right {
        background-color: $color__white;
        border-color: $color__white;
        color: $color__black;
        padding: $padding_arrow;

        &:after {
            @extend %fa-icon;
            @extend .fa-regular;
            @extend .fa-sharp;
            content: fa-content($fa-var-arrow-right-long);
            font-size: 1.5em;
            margin: $margin_arrow-after;
            transition: transform 0.2s linear;
        }
        
        &:hover {
            color: $color__white;
            background-color: $color__green-primary;
            border-color: $color__green-primary;
            text-decoration: none;

            &:after {
                transform: translateX(10px);
            }
        }
    }
    
    &.black-arrow-right {
        background-color: $color__black;
        border-color: $color__black;
        color: $color__white;
        padding: $padding_arrow;

        span:last-of-type {
            margin-top: 3px;
        }
        
        .icon {
            width: 27px;

            &.lot {
                width: 19px;
            }
            
            path {
                stroke: $color__white;
            }
            
            line {
                stroke: $color__white;
            }
            
            .stroke-building02 {
                fill: $color__white;
            }
        }
        
        &:after {
            @extend %fa-icon;
            @extend .fa-regular;
            @extend .fa-sharp;
            content: fa-content($fa-var-arrow-right-long);
            font-size: 1.5em;
            margin: $margin_arrow-after;
            transition: transform 0.2s linear;
        }
        
        &:hover {
            color: $color__white;
            background-color: $color__green-primary;
            border-color: $color__green-primary;
            text-decoration: none;

            &:after {
                transform: translateX(10px);
            }
        }
    }
    
    &.black-chevron-right {
        display: flex;
        justify-content: flex-start;
        background-color: $color__black;
        border-color: $color__black;
        color: $color__white;
        padding: 16px 12px 12px 12px;
        width: 100%;

        .icon {
            width: 15px;

            path {
                stroke: $color__white;
            }
            
            line {
                stroke: $color__white;
            }
            
            .stroke-building02 {
                fill: $color__white;
            }
        }
        
        &:after {
            @extend %fa-icon;
            @extend .fa-regular;
            @extend .fa-sharp;
            content: fa-content($fa-var-chevron-right);
            font-size: 1.25em;
            margin-left: 10px;
            transition: transform 0.2s linear;
        }
        
        &:hover {
            color: $color__white;
            background-color: $color__green-primary;
            border-color: $color__green-primary;
            text-decoration: none;

            &:after {
                transform: translateX(10px);
            }
        }
    }
    
    &.submit-filters {
        font-size: 1.75em;
        height: 55px;
        padding: 10px;
        width: 100%;
        border: 2px solid $color__green-primary;
        color: $color__white;
        background-color: $color__green-primary;

        &:hover {
            color: $color__green-primary;
            background-color: transparent;
        }
    }
    
    .icon {
        width: 25px;
    }
}

.appointement-wrapper {
    position: fixed;
    right: 0px;
    bottom: 0px;
    z-index: 100;
    transition: transform 0.2s linear;

    .accueil & {
        transform: translateY(calc((#{$btn-swiper-nav-height} - 4px) - (#{$btn-swiper-nav-height} * 2)));
    }
    
    .accueil.window-scroll & {
        transform: translateY(0px);
    }
    
    .navbar-btn-appointement {
        // margin-bottom: 45px;
    }
    
    .navbar-btn-appointement,
    .navbar-btn-chat,
    .btn-chat-spacer {
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: $btn-chat-appointement-width;
        // margin-right: 20px;
        background-color: $color__black;
        color: $color__white;
        border: 1px solid $color__black;
        text-transform: uppercase;
        transition: all 0.2s linear;

        .window-scroll & {
            width: $btn-chat-appointement-width-scroll;
            padding: 10px 10px 10px 20px;
            border-color: $color__grey-medium;
        }
        
        .btn-text {
            writing-mode: vertical-lr;
            text-orientation: mixed;
            font-weight: $font__weight-medium;
            letter-spacing: 0.5em;
            transform: rotate(180deg);

            &:after {
                font-family: 'Font Awesome\ 6 Pro';
                font-weight: 400;
                display: inline-block;
                content: '\f078';
                margin-left: 8px;
                margin-top: 6px;
                transition: transform 0.2s linear;
            }
        }
        
        &:hover {
            background-color: $color__green-primary;
            border-color: $color__green-primary;

            .btn-text {
                &:after {
                    transform: translateY(10px);
                }
            }
        }
    }
    
    .btn-chat-spacer {
        background-color: transparent;
        height: 50px;
        border: none;
    }
    
    .navbar-btn-appointement {
        height: $btn-chat-appointement-height;
        padding: 20px 20px 20px 30px;

        .window-scroll & {
            font-size: $font__size-14;
            height: $btn-chat-appointement-height-scroll;
        }
    }
}

.mobile-btn-appointement {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color__white;
    background-color: $color__black;
    font-size: $font__size-12;
    padding: 10px;
    height: 100%;
    width: 130px;
    transition: $transition__color-back;

    @include media-breakpoint-up(lg) {
        padding: 10px 20px;
    }
    
    &:after {
        font-family: 'Font Awesome\ 6 Pro';
        font-size: 0.9em;
        font-weight: 700;
        display: inline-block;
        content: '\f054';
        margin-left: 8px;
        transition: transform 0.2s linear;
    }
    
    &:hover {
        background-color: $color__green-primary;

        &:after {
            transform: translateX(6px);
        }
    }
}

.chat-wrapper {
    position: fixed;
    right: 0px;
    bottom: 0px;
    z-index: 100;
    transition: transform 0.2s linear;

    .accueil & {
        transform: translateY(calc((#{$btn-swiper-nav-height} - 4px) - (#{$btn-swiper-nav-height} * 2)));
    }
    
    .accueil.window-scroll & {
        transform: translateY(0px);
    }
    
    .navbar-btn-chat {}
}

.footer-btn-appointement {
    position: relative;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 65px;
    height: 100%;
    padding: 30px 20px 30px 30px;
    margin-right: 20px;
    color: $color__white;
    border: 1px solid $color__white;
    text-transform: uppercase;
    transition: $transition__color-back, border 0.2s linear;

    // transform-origin: top right;
    .btn-text {
        writing-mode: vertical-lr;
        text-orientation: mixed;
        // position: absolute;
        font-weight: $font__weight-medium;
        letter-spacing: 0.5em;
        transform: rotate(180deg);

        &:after {
            font-family: 'Font Awesome\ 6 Pro';
            font-weight: 400;
            display: inline-block;
            content: '\f078';
            margin-left: 8px;
            transition: transform 0.2s linear;
        }
    }
    
    &:hover {
        border-color: $color__green-primary;
        background-color: $color__green-primary;

        .btn-text {
            &:after {
                transform: translateY(12px);
            }
        }
    }
}

.btn-reset {
    appearance: none;
    display: inline;
    text-align: inherit;
    font-family: inherit;
    font-weight: inherit;
    color: inherit;
    border: none;
    padding: 0;
    text-transform: none;
    letter-spacing: inherit;
    background-color: transparent;

    &:hover,
    &:focus {
        background-color: transparent;
        border: none;
    }
}

.btn-map {
    &:focus {
        color: $color__black;
    }
    
    &.active,
    &:hover {
        color: $color__green-primary;
    }
}

.btn-print {
    transition: color 0.2s linear;
    min-width: unset;

    .icon {
        path {
            transition: fill 0.2s linear;
        }
    }
    
    &:hover,
    &:focus {
        color: $color__green-primary;

        .icon {
            path {
                fill: $color__green-primary;
            }
        }
    }
}

.btn-legend {
    text-decoration: none !important;
    -webkit-appearance: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $color__black;
    font-size: $font__size-14;
    font-weight: $font__weight-bold;
    text-align: center;
    padding: 10px 0px;
    min-width: 55px;
    width: auto;
    letter-spacing: 1px;
    background-color: transparent;
    border: none;
    color: $color__black;

    &:hover {
        color: $color__black;
        background-color: transparent;
        text-decoration: none;
        border: none;
    }
    
    &:focus {
        color: $color__white;
        background-color: transparent;
        text-decoration: none;
    }
}

/*--------------------------------------------------------------
## Links
--------------------------------------------------------------*/
a {
    text-decoration: none;
    font-weight: $font__weight-regular;
    color: inherit;
    transition: $transition__opacity;

    &:hover {
        // opacity: 0.6;
        text-decoration: none;
    }
}

.btn-download {
    position: relative;
    font-size: $font__size-14;
    font-weight: $font__weight-bold;
    text-transform: uppercase;
    padding: 6px 25px 0px 4px;
    transition: $transition__color-link;

    .line {
        position: absolute;
        bottom: 0px;
        right: 0px;
        display: inline-block;
        width: 18px;
        height: 2px;
        background-color: $color__black;
        margin-left: 10px;
        transition: background-color 0.2s linear;
    }
    
    &:before,
    &:after {
        position: absolute;
        display: inline-block;
        content: '';
    }
    
    &:before {
        bottom: 8px;
        right: 4px;
        height: 10px;
        width: 10px;
        border-right: 2px solid $color__black;
        border-bottom: 2px solid $color__black;
        transform: rotate(45deg);
        transition: transform 0.2s linear, border 0.2s linear;
    }
    
    &:after {
        bottom: 8px;
        right: 8px;
        width: 2px;
        height: 14px;
        background-color: $color__black;
        transition: transform 0.2s linear, background-color 0.2s linear;
    }
    
    &.stroke-black {
        display: inline-block;
        padding: 15px 60px 10px 30px;
        border: 2px solid $color__black;

        .line {
            bottom: 12px;
            right: 22px;
            margin-left: 10px;
        }
        
        &:before {
            bottom: 20px;
            right: 26px;
        }
        
        &:after {
            bottom: 20px;
            right: 30px;
        }
    }
    
    &:hover {
        color: $color__green-primary;

        .line {
            background-color: $color__green-primary;
        }
        
        &:before {
            border-color: $color__green-primary;
            transform: translateY(4px) rotate(45deg);
        }
        
        &:after {
            background-color: $color__green-primary;
            transform: translateY(4px);
        }
    }
}

.arrow-left {
    position: relative;
    // padding-left: 40px;
    transition: color 0.2s linear;

    &:before {
        position: relative;
        top: 4px;
        @extend %fa-icon;
        @extend .fa-thin;
        @extend .fa-sharp;
        content: fa-content($fa-var-arrow-left-long);
        font-size: 1.5em;
        margin-right: 10px;
        transition: transform 0.2s linear, color 0.2s linear;
    }
    
    &:hover {
        color: $color__green-primary;
        text-decoration: none;

        &:before {
            transform: translateX(-8px);
        }
    }
}

.arrow-right-bottom {
    position: relative;
    // padding-left: 40px;
    transition: color 0.2s linear;

    &:after {
        position: relative;
        top: 2px;
        @extend %fa-icon;
        @extend .fa-thin;
        @extend .fa-sharp;
        content: fa-content($fa-var-arrow-down-long);
        font-size: 1.4em;
        margin-left: 10px;
        transition: transform 0.2s linear, color 0.2s linear;
    }
}

/*--------------------------------------------------------------
## Links Socials
--------------------------------------------------------------*/
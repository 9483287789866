/*******************************************************
    Live chat styles
*******************************************************/
#chat-widget-container {
    min-width: 0px;
    background-color: transparent;

    @include media-breakpoint-up(lg) {
        right: calc(#{$btn-chat-appointement-width} + 10px) !important;
    }
}
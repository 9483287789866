* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    width: 100%;
    background-color: $color__grey-light;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.page__wrapper {
    // overflow-x: hidden; /* fix for ios safari */
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    padding-right: 20px;
    padding-left: 20px;

    @include media-breakpoint-up(md) {
        padding-right: 30px;
        padding-left: 30px;
    }

    @include media-breakpoint-up(xl) {
        // padding-right: 50px;
        // padding-left: 50px;
    }
}

#page {
    margin-top: calc(#{$promobar__height} + #{$navbar__height-mobile});

    @include media-breakpoint-up(lg) {
        margin-top: calc(#{$promobar__height} + #{$navbar__height});
    }
}

/*******************************************************
    GENERAL
*******************************************************/
$property-detail-padding: 20px;
$property-detail-padding-lg: 30px;

.entry-single-title {
    font-size: 1.3725rem;
    line-height: 1.1666em;
    font-weight: $font__weight-medium;

    @include media-breakpoint-up(lg) {
        font-size: 1.625rem;
        line-height: 1.0769em;
    }
}

.entry-single-title-project-mobile {
    font-size: 1.125rem;
    line-height: 1.1666em;
    font-weight: $font__weight-medium;
}

.entry-main-price {
    position: relative;
    top: 0.11em;
    font-size: 1.8571rem;
    line-height: 0.9454em;
    top: 0.05em;
    font-weight: $font__weight-medium;

    .by-month {
        position: relative;
        bottom: 1px;
        font-size: $font__size-14;
        line-height: inherit;
    }
    
    @include media-breakpoint-up(lg) {
        font-size: 1.3125rem;
        line-height: 1.0769em;
    }
}

.property-detail-info {
    background-color: $color__white;

    .rent-details {
        min-height: 75px;
    }
    
    .row-header {
        border: 2px solid $color__black;

        .col-border {
            border-bottom: 2px solid $color__black;

            @include media-breakpoint-up(lg) {
                border-bottom: none;
            }
            
            &.solo {
                border: none;

                @include media-breakpoint-only(md) {
                    border-bottom: 2px solid $color__black;
                }
            }
        }
        
        .col-border-right {
            border-right: 2px solid $color__black;
            padding: $property-detail-padding;
        }
        
        .col-border-title {
            border-bottom: 2px solid $color__black;
        }
        
        .col-map {
            @include media-breakpoint-only(md) {
                border-top: 2px solid $color__black;
            }
        }
        
        .col-title {
            border-bottom: 2px solid $color__black !important;

            @include media-breakpoint-up(md) {
                border-bottom: none !important;
            }
        }
        
        .row-info-min-height {
            min-height: 74px;
        }
        
        .col-info {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: $property-detail-padding;
            border-bottom: 2px solid $color__black;
            min-height: 74px;

            &:last-of-type {
                border-bottom: none;
            }
            
            @include media-breakpoint-up(sm) {
                border-left: 2px solid $color__black;
                border-bottom: none;

                &:nth-child(2) {
                    border-left: none;
                }
            }
            
            @include media-breakpoint-up(md) {
                &:nth-child(2) {
                    border-left: 2px solid $color__black;
                }
            }
            
            &:first-of-type {
                border-left: none;

                @include media-breakpoint-up(lg) {
                    border-left: 2px solid $color__black;
                }
            }
            
            &.col-info-blueprint-pdf {
                border-bottom: none;
                border-top: 2px solid $color__black;

                @include media-breakpoint-up(sm) {
                    border-top: none;
                }
                
                @include media-breakpoint-up(xl) {
                    border-left: none;
                    border-top: 2px solid $color__black;
                }
            }
            
            &.col-info-price-blueprint {
                @include media-breakpoint-up(sm) {
                    border-left: 2px solid $color__black;
                }
                
                @include media-breakpoint-up(xl) {
                    border-top: 2px solid $color__black;
                }
            }
            
            &.col-info-map {
                border-bottom: 2px solid $color__black;

                @include media-breakpoint-only(sm) {
                    border-bottom: none;
                    border-right: 2px solid $color__black;
                }
                
                @include media-breakpoint-up(md) {
                    border-bottom: none;
                }
            }
            
            &.col-info-pdf {
                border-bottom: none;
            }
            
            &.col-info-price {
                border-bottom: 2px solid $color__black;

                @include media-breakpoint-only(sm) {
                    border-left: none;
                }
                
                @include media-breakpoint-up(md) {
                    border-bottom: none;
                }
            }
            
            &.col-info-map-rent {
                border-top: 2px solid $color__black;
                border-bottom: none;

                @include media-breakpoint-only(sm) {
                    // border-bottom: none;
                    // border-right: 2px solid $color__black;
                }
                
                @include media-breakpoint-up(md) {
                    border-top: none;
                    border-left: 2px solid $color__black;
                }
            }
            
            &.col-info-pdf-rent {
                border-bottom: none;
                border-top: 2px solid $color__black;

                @include media-breakpoint-only(sm) {
                    border-left: 2px solid $color__black;
                    border-top: 2px solid $color__black;
                }
                
                @include media-breakpoint-up(md) {
                    border-top: none;
                }
            }
            
            &.col-info-price-rent {
                @include media-breakpoint-up(sm) {
                    border-left: none;
                }
                
                @include media-breakpoint-up(lg) {
                    border-left: 2px solid $color__black;
                }
            }
            
            &.col-info-map-lot {
                border-top: 2px solid $color__black;
                border-bottom: none;

                @include media-breakpoint-up(lg) {
                    border-top: none;
                }
            }
            
            &.col-info-price-lot {
                border-left: 2px solid $color__black;
            }
            
            &.col-info-map-project {
                border-top: 2px solid $color__black;

                @include media-breakpoint-up(md) {
                    border-top: none;
                }
            }
        }
    }
    
    .row-body {
        border-bottom: 2px solid $color__black;
        border-right: 2px solid $color__black;

        p {
            line-height: 1.4em !important;
        }
        
        .col-info {
            border-left: 2px solid $color__black;

            @include media-breakpoint-up(lg) {
                &.lot {
                    max-width: 260px;
                }
            }
        }
        
        .col-info:not(.empty) {
            padding: $property-detail-padding-lg $property-detail-padding-lg 15px $property-detail-padding;

            @include media-breakpoint-up(md) {
                padding: $property-detail-padding-lg $property-detail-padding-lg $property-detail-padding-lg $property-detail-padding;
            }
            
            @include media-breakpoint-up(lg) {
                &:first-of-type:not(.lot) {
                    padding-right: 125px;
                }
            }
        }
        
        .col-info-desctiption {
            padding: 0px $property-detail-padding-lg 15px $property-detail-padding !important;

            @include media-breakpoint-up(md) {
                padding: $property-detail-padding-lg $property-detail-padding-lg $property-detail-padding-lg $property-detail-padding !important;
            }
        }
        
        .col-map {
            border-left: 2px solid $color__black;
            border-bottom: 2px solid $color__black;
        }
    }
}

/*******************************************************
    Single a project
*******************************************************/
.container-header-project-nav {
    position: sticky;
    top: calc(#{$navbar__height-mobile} - 2px);
    background-color: $color__grey-light;
    z-index: 20;
}

.container-footer-project-nav {
    position: sticky;
    bottom: 0px;
    width: 100%;
    background-color: $color__grey-light;
    z-index: 2;
    // border-top: 1px solid $color__black;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
}

/*******************************************************
    espace Prestige
*******************************************************/
#page.espace-prestige {
    background-color: $color__white;
}

/*******************************************************
    loan-calculator
*******************************************************/
#loan-calculator-wrapper {
    padding: 40px;
    background-color: $color__white;
}

.loan-calculator {
    padding: 40px 20px 20px 20px;
    background-color: $color__grey-light;

    .input-box {
        display: flex;
        flex-direction: column;

        input[type='text'] {
            display: block;
            color: $color__grey-medium;
            background-color: $color__white;
        }
    }
}

/*******************************************************
    Services
*******************************************************/
.section-service-forms {}
// Include any default variable overrides here
// @import 'variables';
/* --------------------------------------------------------------
# bootstrap@5.3.1 sass files
-------------------------------------------------------------- */
// @import '~bootstrap/scss/bootstrap';
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import '~bootstrap/scss/functions';
// 2. Include any default variable overrides here
@import 'variables';
// 3. Include remainder of required Bootstrap stylesheets
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/maps";
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/root';
// 4. Include any optional Bootstrap CSS as needed
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
// @import "~bootstrap/scss/images";
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/helpers';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/accordion';
@import '~bootstrap/scss/modal';
// @import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/dropdown';
// @import '~bootstrap/scss/popover';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/offcanvas';
// @import '~bootstrap/scss/forms/floating-labels';
// @import '~bootstrap/scss/forms/form-check';
// @import '~bootstrap/scss/forms/form-control';
@import '~bootstrap/scss/forms/form-range';
// @import '~bootstrap/scss/forms/form-select';
// @import '~bootstrap/scss/forms/form-text.scss';
// @import '~bootstrap/scss/forms/input-group.scss';
// @import '~bootstrap/scss/forms/labels';
// @import '~bootstrap/scss/forms/validation';
// 5. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import '~bootstrap/scss/utilities/api';
/* --------------------------------------------------------------
# Fontawesome Pro 6.4
-------------------------------------------------------------- */
@import '~@fortawesome/fontawesome-pro/scss/fontawesome';
@import '~@fortawesome/fontawesome-pro/scss/thin';
@import '~@fortawesome/fontawesome-pro/scss/light';
@import '~@fortawesome/fontawesome-pro/scss/regular';
// @import '~@fortawesome/fontawesome-pro/scss/solid';
// @import '~@fortawesome/fontawesome-pro/scss/duotone';
@import '~@fortawesome/fontawesome-pro/scss/brands';
@import '~@fortawesome/fontawesome-pro/scss/sharp-light';
// @import '~@fortawesome/fontawesome-pro/scss/sharp-solid';
@import '~@fortawesome/fontawesome-pro/scss/sharp-regular';
/* --------------------------------------------------------------
# Swiper 11.0.1
-------------------------------------------------------------- */
// imported in JS
@import '~swiper/swiper-bundle.css';
/* --------------------------------------------------------------
# Lightgallery
-------------------------------------------------------------- */
@import '~lightgallery/css/lightgallery.css';
@import '~lightgallery/css/lg-fullscreen.css';
@import '~lightgallery/css/lg-zoom.css';
@import '~lightgallery/css/lg-thumbnail.css';
/*******************************************************
    Reset
*******************************************************/
@import 'reset';
/*******************************************************
    Global
*******************************************************/
@import 'global';
/*******************************************************
    Font
*******************************************************/
@import 'fonts';
/*******************************************************
    Typography
*******************************************************/
@import 'typography';
/*******************************************************
    Texte editor
*******************************************************/
@import 'text-editor';
/*******************************************************
   Text-editor
*******************************************************/
// @import 'text-editor';
/*******************************************************
    Transitions
*******************************************************/
// @import 'transitions';
/*******************************************************
    List
*******************************************************/
// @import 'list';
/*******************************************************
    Colors
*******************************************************/
@import 'colors';
/*******************************************************
    Utilities
*******************************************************/
@import 'utilities';
/*******************************************************
    Spacing
*******************************************************/
@import 'spacing';
/*******************************************************
    Navigation
*******************************************************/
@import 'navigation';
/*******************************************************
    Forms
*******************************************************/
@import 'forms';
/*******************************************************
    Filters
*******************************************************/
@import 'filters';
/*******************************************************
    Entries single
*******************************************************/
@import 'entries';
/*******************************************************
    Pagination
*******************************************************/
// @import 'pagination';
/*******************************************************
    Buttons
*******************************************************/
@import 'buttons-links';
/*******************************************************
    Icons & logos
*******************************************************/
@import 'icons-logos';
/*******************************************************
    Labels
*******************************************************/
// @import 'labels';
/*******************************************************
    Hero
*******************************************************/
// @import 'hero';
/*******************************************************
    Accordions
*******************************************************/
@import 'accordions';
/*******************************************************
    Modals
*******************************************************/
@import 'modals';
/*******************************************************
    Swipper
*******************************************************/
@import 'swiper';
/*******************************************************
    Cards
*******************************************************/
@import 'cards';
/*******************************************************
    Medias
*******************************************************/
@import 'medias';
/*******************************************************
    Matrix
*******************************************************/
@import 'matrix';
/*******************************************************
    Contact
*******************************************************/
// @import 'contact';
/*******************************************************
    Index
*******************************************************/
@import 'index';
/*******************************************************
    Map
*******************************************************/
@import 'map';
/*******************************************************
    Pagination
*******************************************************/
@import 'pagination';
/*******************************************************
    Residential project
*******************************************************/
@import 'residential-project';
/*******************************************************
    Tabs
*******************************************************/
@import 'tabs';
/*******************************************************
    Offcanvas
*******************************************************/
@import 'offcanvas';
/*******************************************************
    Chat module
*******************************************************/
@import 'chat';
/*******************************************************
    Footer
*******************************************************/
@import 'footer';
/*******************************************************
    Print
*******************************************************/
@import 'print';
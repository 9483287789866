/*******************************************************
    Logo
*******************************************************/
.lachance-logo {}

/*******************************************************
    Icons
*******************************************************/
.icon {
    width: 22px;

    &.lot {
        width: 15px;
    }
    
    &.photo {
        width: 18px;
    }
    
    &.video {
        width: 30px;
    }
}

.property-detail-info {
    .icon.lot {
        width: 20px;
    }
}
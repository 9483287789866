
/*--------------------------------------------------------------
## Pagination
--------------------------------------------------------------*/


.pagination-navigation{
    display: flex;
    flex-direction: row;
    font-size: $font__size-14;

    .page-element{
        padding: 1px;

        &.elipsis{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
        }

        .page-link{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            width: 40px;
            border: 1px solid transparent;
            transition: $transition__color-link;

            &.disable{
                pointer-events: none;
                color: $color__black-40;
            }

            &:hover{
                color: $color__green-primary;
            }

            &.page-link-active{
                color: $color__green-primary;
                border-color: $color__green-primary;
            }

        }

        .previous-link-page,
        .next-link-page{
            width: auto;
        }

    }
}

/*******************************************************
    Footer
*******************************************************/

#footer-main{
    max-width: 100%;

    .footer-top-wrapper{
        padding: 80px 0px;
        background-color: $color__black;
    }
    .footer-bottom-wrapper{
        padding: 20px 0px;
        background-color: $color__white;
    }
}



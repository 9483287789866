/*******************************************************
    Navigation general
*******************************************************/
.menu {
    &.inline {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    
    &.inline-stretch {
        display: flex;
        flex-direction: row;
        align-items: stretch;
    }
    
    &.stack {
        // display: flex;
        // flex-direction: column;
    }
}

/*******************************************************
    Promo bar
*******************************************************/
#promo-bar {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: $promobar__height;
    padding: 0px 0px;
    background-color: $color__black;
    transition: $transition__transform;
    z-index: 999;

    .window-scroll & {
        transform: translateY(-100%);
    }
    
    .promo-bar-text {
        color: $color__white;
        font-weight: $font__weight-medium;
        margin-bottom: 0px;
    }
    
    .promo-bar-link {
        font-size: $font__size-12;
        font-weight: $font__weight-medium;
    }
}

/*******************************************************
    Navbar
*******************************************************/
#navbar-top {
    position: fixed;
    top: $promobar__height;
    left: 0px;
    width: 100%;
    height: $navbar__height-mobile;
    background-color: $color__white;
    transition: $transition__transform, height 0.2s linear;
    z-index: 999;

    @include media-breakpoint-up(lg) {
        height: $navbar__height;
    }
    
    .lachance-logo {
        width: 150px;
        transition: width 0.2s linear;

        @include media-breakpoint-up(md) {
            width: 175px;
        }
        
        @include media-breakpoint-up(xl) {
            width: 240px;
        }
    }
    
    .window-scroll & {
        transform: translateY(calc($promobar__height - (($promobar__height * 2) + 1px)));
        height: $navbar__height-mobile;
        border-bottom: 1px solid $color__grey-medium;

        @include media-breakpoint-up(lg) {
            height: $navbar__height-scroll;
        }
        
        .lachance-logo {
            max-width: 175px;
        }
    }
    
    .navbar-deploy & {
        height: $navbar__height;

        .lachance-logo {
            max-width: 240px;
        }
    }
    
    .wrapper-menu {
        // margin-right: $btn-appointement-height;
    }
}

/*******************************************************
    Menu navbar
*******************************************************/
.menu-navbar {
    position: static;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    height: 100%;

    .menu-item {
        position: static;
        display: flex;
        align-items: stretch;
        padding: 0px;

        .nav-link {
            position: relative;
            top: $font__top-position-correction;
            display: flex;
            align-items: center;
            font-size: $font__size-14;
            padding: 5px 8px;

            @include media-breakpoint-up(xl) {
                font-size: $font__size-body;
                padding: 5px 12px;
            }
            
            @include media-breakpoint-up(xxl) {
                padding: 10px 16px 5px 16px;
            }
            
            &:hover {
                color: $color__green-primary;
                opacity: 1;
            }
            
            &.active {
                color: $color__green-primary;
            }
        }
        
        &:first-of-type {
            .nav-link {
                font-weight: $font__weight-bold;
            }
        }
        
        &.menu-item-has-children {
            position: relative;

            .dropdown-toggle {
                height: 100%;

                &:before,
                &:after {
                    display: none;
                }
            }
            
            .menu-submenu {
                position: absolute;
                opacity: 0;
                pointer-events: none;
                bottom: 1px;
                left: 50%;
                width: 350px;
                // padding: 30px 0px 0px 0px;
                background-color: $color__white;
                border: 1px solid $color__grey-medium;
                transition: transform 0.3s ease-in-out, opacity 0.3s linear;
                transform: translate(-50%, 85%);

                &:before {
                    display: none;
                }
                
                .menu-item {
                    display: block;
                    width: 100%;

                    .sublinks-wrapper {
                        color: $color__black;
                        font-weight: $font__weight-medium;
                        padding: 0px;

                        .submenu-link {
                            display: block;
                            width: 100%;
                            padding: 12px 16px;

                            a {
                                padding: 24px 0px;
                                font-weight: $font__weight-medium;
                                transition: $transition__color-link;
                            }
                            
                            &:hover {
                                color: $color__green-primary;
                            }
                        }
                        
                        .submenu-link-all {
                            padding: 12px 16px 8px 16px;

                            a {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                align-items: center;

                                span {
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;

                                    .icon-arrow {
                                        font-size: 1.75em;
                                        margin-left: 15px;
                                        transition: $transition__transform;
                                    }
                                    
                                    &.active {
                                        color: $color__green-primary;
                                    }
                                }
                                
                                img {
                                    width: 25px;
                                    margin-right: 10px;
                                }
                                
                                .submenu-text-all {
                                    margin-top: 4px;

                                    &.active {
                                        color: $color__green-primary;
                                    }
                                }
                            }
                        }
                        
                        &:hover {
                            .submenu-icon {
                                &:before {
                                    transform: translate(-50%, -50%) rotate(180deg);
                                }
                                
                                &:after {
                                    transform: translate(-50%, -50%) rotate(270deg);
                                }
                            }
                            
                            .icon-arrow {
                                transform: translateX(4px);
                            }
                        }
                    }
                    
                    &.menu-item-all {
                        .submenu-link-all {
                            border-bottom: 1px solid $color__grey-medium;
                        }
                        
                        &:last-of-type {
                            .submenu-link-all {
                                border-bottom: none
                            }
                        }
                    }
                }
            }
            
            .nav-link-submenu {
                &:hover {
                    .menu-submenu {
                        opacity: 1;
                        visibility: visible;
                        pointer-events: auto;
                        transform: translate(-50%, 100%);
                    }
                }
            }
        }
    }
}

.navbar-btn-rent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: $font__size-14;
    line-height: 1.45em;
    padding: 14px;
    margin-left: 16px;
    width: $navbar__btn-rent-width;
    color: $color__white;
    background-color: $color__green-primary;

    .window-scroll & {
        line-height: $font__line-height-body;
    }
    
    @include media-breakpoint-up(xl) {
        font-size: $font__size-body;
        padding: 20px;
    }
}

/*******************************************************
    Mobile menu
*******************************************************/
$mobile-menu-padding: 20px;

#mobile-menu-panel {
    position: fixed;
    z-index: 1000001;
    top: 0px;
    right: 0px;
    height: 100vh;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: $color__white;
    opacity: 0;
    transition: transform 0.4s ease-out, opacity 0.5s linear;
    transform: translateX(-110%);

    .mobile-menu-open & {
        transform: translateX(0%);
        opacity: 1;
    }
    
    .mobile-menu-header {
        background-color: $color__black;
        padding: 10px $mobile-menu-padding;

        .lachance-logo-mobile {
            width: 30px;
        }
    }
    
    .mobile-menu-close-wrapper {
        display: flex;
        justify-content: flex-end;
        padding: $mobile-menu-padding;

        .btn-menu-close {
            width: 20px;

            .icon-close {
                font-size: 1.5rem;
            }
            
            &:hover {
                background-color: inherit;
                color: inherit;
            }
        }
    }
    
    .mobile-menu-body {
        padding-top: $mobile-menu-padding;
        padding-bottom: $mobile-menu-padding;
    }
}

.menu-mobile {
    .menu-item {
        padding: 0px;
        transform: $transition__color-back;

        &.main-element {
            border-bottom: 1px solid $color__black;

            &:first-of-type {
                border-top: 1px solid $color__black;
            }
            
            .nav-link {
                display: flex;
                flex-direction: row;
                align-items: center;
                min-height: 58px;

                .icon-wrapper {
                    display: inline-flex;
                    flex-direction: row;
                    justify-content: center;
                    width: 40px;

                    img {
                        height: 28px;
                    }
                }
                
                span {
                    position: relative;
                    top: $font__top-position-correction;
                }
            }
        }
        
        .nav-link {
            font-weight: $font__weight-medium;
            display: block;
            padding: 16px $mobile-menu-padding 16px $mobile-menu-padding;
        }
    }
    
    .menu-item-has-children {
        .nav-link-mobile-submenu {
            display: flex;
            justify-content: space-between;

            .icon-submenu {
                font-size: 1.5rem;
                transition: transform 0.2s linear;
            }
        }
        
        &:hover {
            .nav-link-mobile-submenu {
                .icon-submenu {
                    transform: translateX(10px);
                }
            }
        }
        
        .menu-submenu-wrapper {
            position: absolute;
            z-index: 2100;
            top: 120px;
            right: 0px;
            height: calc(100vh - 120px);
            width: 100%;
            overflow-y: auto;
            background-color: $color__white;
            opacity: 0;
            transition: transform 0.4s ease-out, opacity 0.3s linear;
            transform: translateX(-110%);

            &.show {
                opacity: 1;
                transform: translateX(0%);
            }
            
            .mobile-submenu-back {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: $mobile-menu-padding;

                .icon-back {
                    font-size: 1.5rem;
                }
            }
        }
    }
}

.animate-submenu {
    animation-name: animateIn;
    animation-duration: 400ms;
    animation-delay: calc(var(--animation-order) * 125ms);
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
}

@keyframes animateIn {
    0% {
        opacity: 0;
        transform: translateY(8px);
    }
    
    100% {
        opacity: 1;
    }
}

/*******************************************************
    Navigation footer
*******************************************************/
.menu-footer-wrapper {
    margin-right: 40px;

    &:last-of-type {
        margin-right: 0px;
    }
}

.menu-footer {
    display: flex;
    flex-direction: column;

    .menu-item {
        display: inline-block;
        margin-bottom: 2px;

        .menu-title {
            display: inline-block;
            font-size: 0.875rem;
            padding-top: 6px;
            margin-bottom: 22px;
            font-weight: $font__weight-bold;
            color: $color__green-primary;
            text-transform: uppercase;
        }
        
        .nav-link {
            display: inline-block;
            font-size: 0.875rem;
            font-weight: $font__weight-medium;
            color: $color__white;
            padding: 8px 0px;

            &:after {
                display: block;
                content: '';
                width: 0%;
                height: 1px;
                background-color: $color__white;
                transition: width 0.2s linear;
            }
            
            &.active,
            &:hover {
                &:after {
                    width: 100%;
                }
            }
        }
    }
    
    &.small {

        .menu-title,
        .nav-link {
            font-size: 0.75rem;
        }
    }
}

/*--------------------------------------------------------------
## Hamburger
--------------------------------------------------------------*/
.menu-hamburger {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;
    width: 45px;
    min-width: unset;
    height: 30px;
    padding: 4px;
    border-radius: 0px;

    &:hover {
        background-color: transparent;
    }
    
    &:focus {
        color: inherit;
        border: none;
        background-color: transparent;
    }
}

.line {
    fill: none;
    stroke: black;
    stroke-width: 3;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line1 {
    stroke-dasharray: 60 207;
    stroke-width: 3;
}

.line2 {
    stroke-dasharray: 60 60;
    stroke-width: 3;
}

.line3 {
    stroke-dasharray: 60 207;
    stroke-width: 3;
}

.opened .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 3;
}

.opened .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 3;
}

.opened .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 3;
}

/*--------------------------------------------------------------
## Project navigation
--------------------------------------------------------------*/
.project-navigation {
    .project-menu-group {
        padding: 25px 30px 15px 30px;
        border-bottom: 2px solid $color__black;

        &:last-of-type {
            border-bottom: none;
        }
        
        .project-menu-group-title {
            font-size: $font__size-body;
            font-weight: $font__weight-bold;
            margin-bottom: 8px;
        }
        
        .project-cat-menu {
            .menu-item {
                .project-link {
                    color: $color__black;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    font-family: $font__heading;
                    font-weight: $font__weight-regular;
                    text-transform: none;
                    font-size: $font__size-body;
                    justify-content: flex-start;
                    min-height: 30px;
                    padding: 8px 30px 8px 0px;
                    transition: $transition__color-link;

                    .icon-wrapper {
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        height: 30px;
                        width: 45px;
                        content: "";
                        display: block;
                        transition: transform 0.2s linear;

                        &:before,
                        &:after {
                            position: absolute;
                            content: "";
                            display: block;
                            transition: transform 0.2s linear, background-color 0.2s linear, border-color 0.2s linear;

                            @include media-breakpoint-up(md) {
                                width: 20px;
                                height: 3px;
                            }
                        }
                        
                        &:before {
                            top: calc(50% + 1px);
                            right: 0px;
                            width: 22px;
                            height: 2px;
                            background-color: $color__black;
                            transition: background-color 0.2s linear;
                        }
                        
                        &:after {
                            top: 50%;
                            right: 5px;
                            width: 12px;
                            height: 12px;
                            border-right: 2px solid $color__black;
                            border-top: 2px solid $color__black;
                            transform: rotate(45deg) translateY(-50%);
                            transition: color 0.2s linear;
                        }
                    }
                    
                    &:hover,
                    &.active {
                        color: $color__green-primary;

                        .icon-wrapper {
                            &:before {
                                background-color: $color__green-primary;
                            }
                            
                            &:after {
                                border-color: $color__green-primary;
                            }
                        }
                    }
                    
                    &:hover {
                        .icon-wrapper {
                            transform: translateX(8px);
                        }
                    }
                }
            }
        }
    }
}
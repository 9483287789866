.hide {
    display: none;
}

/*--------------------------------------------------------------
## Filter wrapper
--------------------------------------------------------------*/
.filtering {
    .filtering-header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 0px;

        @include media-breakpoint-up(sm) {}
        
        .filter-section-link {
            position: relative;
            text-align: center;
            width: 100%;
            background-color: $color__white;
            padding: 16px 20px 10px 20px;
            font-size: $font__size-13;
            font-weight: $font__weight-bold;
            letter-spacing: 0.0625em;
            border-top: 1px solid $color__black;
            border-right: 1px solid $color__black;
            border-left: 1px solid $color__black;
            text-transform: uppercase;
            transition: $transition__color-back;

            @include media-breakpoint-up(sm) {
                width: auto;
                border: 1px solid $color__black;
            }
            
            @include media-breakpoint-up(md) {
                font-size: $font__size-14;
                padding: 16px 24px 10px 24px;
            }
            
            &:hover,
            &.active {
                color: $color__white;
                background-color: $color__black;

                .arrow {
                    color: $color__green-primary;
                    transform: rotate(90deg) translate(-35%, 10%);
                }
            }
        }
    }
    
    .filtering-body {
        padding: 25px;
        background-color: $color__black;
    }
    
    .filtering-footer {}
}

/*--------------------------------------------------------------
## Filters element
--------------------------------------------------------------*/
// search
.input-wrapper-search {
    position: relative;
    color: $color__black;
    background-color: $color__white;

    input {
        height: 55px;
        padding: 16px 16px 10px 45px;
    }
    
    &:after {
        position: absolute;
        top: 16px;
        color: $color__black;
        left: 8px;
        display: block;
        font-family: Font Awesome\ 6 Pro;
        font-size: 1.55rem;
        font-weight: 300;
        content: "\f002";
        transform: rotate(90deg);
    }
}

// Calendar
.input-wrapper-calendar {
    position: relative;
    color: $color__black;
    background-color: $color__white;

    .icon {
        position: absolute;
        top: 50%;
        right: 20px;
        color: $color__green-primary;
        font-size: 1.85rem;
        transform: translateY(-50%);
        pointer-events: none;
    }
    
    input {
        height: 55px;
        padding: 16px 16px 10px 16px;
    }
}

// Dropdown for checkbox
.dropdown-toggle {
    position: relative;
    justify-content: flex-start;
    font-size: $font__size-14;
    padding: 16px 30px 10px 10px;
    height: 55px;
    width: 100%;
    text-transform: none;
    background-color: $color__white;
    color: $color__black;
    font-weight: $font__weight-regular;
    border: none;

    &:hover,
    &:focus {
        background-color: $color__white;
        color: $color__black;
    }
    
    &:before,
    &:after {
        position: absolute;
        top: 50%;
        content: "";
        width: 8px;
        height: 2px;
        background-color: $color__green-primary;
        border: none;
        display: block;
        margin-left: 0.15em;
        pointer-events: none;
        transition: transform 0.2s linear;
    }
    
    &:before {
        right: 13px;
        transform: rotate(-45deg);
    }
    
    &:after {
        right: 18px;
        transform: rotate(45deg);
    }
    
    &.show {
        &:before {
            transform: rotate(45deg);
        }
        
        &:after {
            transform: rotate(-45deg);
        }
    }
    
    &.filters {
        &:before {
            display: none;
        }
        
        &:after {
            top: 38%;
            right: 40px;
            content: "\e253";
            font-family: Font Awesome\ 6 Sharp;
            font-weight: 400;
            font-size: 1.5em;
            color: $color__green-primary;
            width: 0px;
            height: 0px;
            background-color: transparent;
            transform: rotate(0deg);
            display: block;
        }
    }
}

.dropdown-menu {
    padding: 20px;
    border-radius: 0px;
    width: 100%;
    background-color: $color__black;

    &:before {
        content: "";
        display: block;
        height: 2px;
        width: 100%;
        margin-bottom: 20px;
        background-color: $color__grey-medium;
    }
}

.fieldset-radio-filters {
    display: flex;
    flex-direction: row;

    .radio-filter-wrapper {
        text-align: center;
        padding: 0px 5px;

        label {
            text-align: center;

            i.icon {
                width: auto;
                margin-bottom: 6px;
                margin-left: auto;
                margin-right: auto;
            }
        }
        
        input[type="radio"] {
            display: none;
        }
        
        input[type="radio"] {

            &:checked+label,
            &:checked+.icon {
                color: $color__green-primary;
            }
        }
    }
}

.col-map-icon {
    transition: $transition__color-back;

    .checkbox-map-filter-wrapper {
        input[type="checkbox"] {
            display: none;
        }
        
        label {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;

            .icon {
                order: 2;
                margin-left: 8px;
            }
            
            span {
                order: 1;
            }
        }
        
        input[type="checkbox"] {

            &:checked+label,
            &:checked+.icon {
                // color: $color__green-primary;
            }
        }
    }
    
    &.dark {
        background-color: $color__black;

        label {
            color: $color__white;
        }
    }
}

/*--------------------------------------------------------------
## Checkbox dropdown
--------------------------------------------------------------*/
.checkbox-dropdown {
    position: relative;
    width: 100%;
    height: 55px;
    font-size: $font__size-14;
    // padding: 4px 0px 0px 10px;
    background-color: $color__white;

    button {
        font-size: $font__size-14;
        letter-spacing: 0px;
    }
    
    &>label {
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        font-size: $font__size-14;
        width: 100%;
        height: 100%;

        &:before,
        &:after {
            position: absolute;
            top: 44%;
            content: "";
            width: 8px;
            height: 2px;
            background-color: $color__green-primary;
            border: none;
            display: block;
            margin-left: 0.15em;
            pointer-events: none;
            transition: transform 0.2s linear;
        }
        
        &:before {
            right: 13px;
            transform: rotate(-45deg);
        }
        
        &:after {
            right: 18px;
            transform: rotate(45deg);
        }
    }
    
    .dropdown-menu {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        transform: translateY(100%);
        padding: 20px;
        border-radius: 0px;
        width: 100%;
        background-color: $color__black;
        z-index: 1000;

        &:before {
            content: "";
            display: block;
            height: 2px;
            width: 100%;
            margin-bottom: 20px;
            background-color: $color__grey-medium;
        }
        
        .checkbox-input-wrapper {
            label {
                font-size: $font__size-14;
            }
        }
        
        .checkbox-dropdown-menu {
            left: -20px;
            width: calc(100% + 40px);
        }
    }
    
    &>input[type="checkbox"] {
        &:checked+label {
            &:before {
                transform: rotate(45deg);
            }
            
            &:after {
                transform: rotate(-45deg);
            }
        }
    }
}

/*--------------------------------------------------------------
## AlpineJS accordeons
--------------------------------------------------------------*/
.accordeon-wrapper {
    position: relative;

    .accordeon-btn {
        position: relative;
        justify-content: flex-start;
        font-size: $font__size-14;
        padding: 12px 30px 10px 10px;
        height: 55px;
        width: 100%;
        text-transform: none;
        background-color: $color__white;
        color: $color__black;
        font-weight: $font__weight-regular;
        border: none;

        &:hover,
        &:focus {
            background-color: $color__white;
            color: $color__black;
        }
        
        &:before,
        &:after {
            position: absolute;
            top: 50%;
            content: "";
            width: 8px;
            height: 2px;
            background-color: $color__green-primary;
            border: none;
            display: block;
            margin-left: 0.15em;
            pointer-events: none;
            transition: transform 0.2s linear;
        }
        
        &:before {
            right: 13px;
            transform: rotate(-45deg);
        }
        
        &:after {
            right: 18px;
            transform: rotate(45deg);
        }
        
        &.show {
            &:before {
                transform: rotate(45deg);
            }
            
            &:after {
                transform: rotate(-45deg);
            }
        }
        
        &.filters {
            &:before {
                display: none;
            }
            
            &:after {
                top: 38%;
                right: 40px;
                content: "\e253";
                font-family: Font Awesome\ 6 Sharp;
                font-weight: 400;
                font-size: 1.5em;
                color: $color__green-primary;
                width: 0px;
                height: 0px;
                background-color: transparent;
                transform: rotate(0deg);
                display: block;
            }
        }
    }
    
    .accordeon-content {
        padding: 20px;
        border-radius: 0px;
        width: 100%;
        background-color: $color__black;
        z-index: 1000;
        border: 1px solid $color__white;

        &:before {
            content: "";
            display: block;
            height: 2px;
            width: 100%;
            margin-bottom: 20px;
            background-color: $color__grey-medium;
        }
        
        @include media-breakpoint-up(lg) {
            position: absolute;
        }
    }
}

/***********************************
    Reset button
***********************************/
.filter-reset-btn {
    display: inline-block;
    padding: 4px 8px;
    margin: 2px 0px;
    font-size: $font__size-14;
    font-weight: $font__weight-medium;
    color: $color__green-primary;
    cursor: pointer;

    @include media-breakpoint-up(md) {
        margin: 6px 0px 4px 0px;
    }
    
    &:after {
        font-family: Font Awesome\ 6 Pro;
        ;
        font-weight: 400;
        content: "\f00d";
        font-size: 1.25em;
        color: $color__black;
        display: inline-block;
        margin-left: 4px;
    }
}

/***********************************
    Spinner
***********************************/
.loader-wrapper {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.6);
    transition: $transition__opacity;
    z-index: 2000;

    .lachance-loader {
        position: relative;
        top: 40vh;
        width: 100px;
        height: auto;
        left: 50%;
        transform: translate(-50%, -50%);

        .loader-style {
            fill: #ffffff;
            animation-duration: 1s;
            animation-delay: 0s;
            animation-name: loaderTransition;
            animation-iteration-count: infinite;
        }
    }
    
    &.htmx-request {
        opacity: 1;
        pointer-events: all;
    }
}

@keyframes loaderTransition {
    0% {
        fill: #ffffff;
    }
    
    50% {
        fill: #000000;
    }
    
    100% {
        fill: #ffffff;
    }
}
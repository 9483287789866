/***************************************************************
    Offcanvas
***************************************************************/
$padding_offcanvas_left: 40px;
$padding_offcanvas_top: 30px;

.offcanvas-backdrop {
    display: none;

    @include media-breakpoint-up(lg) {
        display: block;
    }
}

.offcanvas {
    color: $color__white;
    padding: 0px;

    &.offcanvas-end {
        top: calc(#{$promobar__height} + #{$navbar__height-mobile});
        width: 550px;
    }
    
    .window-scroll & {
        top: #{$navbar__height-mobile};
    }
    
    .project {
        display: none;
        pointer-events: none;
        height: calc(100vh - (#{$promobar__height} + #{$navbar__height-mobile}));

        .window-scroll & {
            height: calc(100vh - #{$navbar__height-mobile});
        }
        
        @include media-breakpoint-up(lg) {
            display: block;
            pointer-events: auto;
        }
    }
    
    .legend {}
    
    @include media-breakpoint-up(lg) {
        &.offcanvas-end {
            top: calc(#{$promobar__height} + #{$navbar__height});
            height: calc(100vh - (#{$promobar__height} + #{$navbar__height}));
        }
        
        .window-scroll & {
            top: #{$navbar__height-scroll};
            height: calc(100vh - #{$navbar__height-scroll});
        }
    }
    
    .offcanvas-header {
        padding: $padding_offcanvas_top 15px $padding_offcanvas_top $padding_offcanvas_left;
        background-color: $color__black;

        .btn-close-offcanvas {
            min-width: auto;
            margin-right: 8px;
        }
        
        .icon.lot {
            color: $color__white;
            width: 20px;
            margin-right: 25px;

            path {
                stroke: $color__white;
            }
        }
    }
    
    .offcanvas-body {
        padding: 10px 0px 75px 0px;

        &::-webkit-scrollbar {
            width: 1em;
            border: 1px solid $color__grey-medium;
        }
        
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 16px rgba(0, 0, 0, 0.3);
            // background-color: rgba(0, 0, 0, 0.3);
        }
        
        &::-webkit-scrollbar-thumb {
            background-color: $color__grey-medium;
            outline: 1px solid $color__grey-medium;
        }
    }
}

.offcanvasPop {
    opacity: 0;
    position: absolute;
    bottom: 0px;
    right: 0px;
    // transform: translate(-99%, 99%);
    transform: translate(0px, 98%);
    color: $color__white;
    padding: 0px;
    width: 450px;
    // height: calc(100vh - (#{$promobar__height} + #{$navbar__height}));
    pointer-events: none;
    transition: opacity 0.3s linear, transform 0.3s linear;

    @include media-breakpoint-up(lg) {
        transform: translate(-85px, 98%);
    }
    
    .offcanvas-body {
        padding: 10px 0px 75px 0px;
        background-color: $color__white;

        &::-webkit-scrollbar {
            width: 1em;
            border: 1px solid $color__grey-medium;
        }
        
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 16px rgba(0, 0, 0, 0.3);
            // background-color: rgba(0, 0, 0, 0.3);
        }
        
        &::-webkit-scrollbar-thumb {
            background-color: $color__grey-medium;
            outline: 1px solid $color__grey-medium;
        }
    }
}
/***********************************
    General
***********************************/
.bg-img {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.bg-repeat {
    background-repeat: repeat;
}

/***********************************
    Separator
***********************************/
.sep-hor-white {
    width: 100%;
    height: 1px;
    background-color: $color__white;
}

.sep-hor-grey-medium {
    width: 100%;
    height: 1px;
    background-color: $color__grey-medium;
}

.sep-hor-black {
    width: 100%;
    height: 1px;
    background-color: $color__black;
}

.sep-hor-black-02 {
    width: 100%;
    height: 2px;
    background-color: $color__black;
}

/***********************************
    Borders
***********************************/
.border-top-black-02 {
    border-top: 2px solid $color__black;
}
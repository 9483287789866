/*--------------------------------------------------------------
## Index Team
--------------------------------------------------------------*/

.equipe-index{
    padding: 45px 0px;

    .row-team{
        padding: 50px 0px 10px 0px;

        @include media-breakpoint-up(md) {
            padding: 85px 0px 20px 0px;
        }
    }

}


